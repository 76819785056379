/* --------------------------------------------------------------- */

import Box from '@mui/material/Box';

/* --------------------------------------------------------------- */

/*
  A view with only left padding for content elements. It's up to
  the content owner to set the margin and padding.
*/
function LcDarkView(props) {
 //     bgcolor="tertiary.main"  
  return (
    <Box
      sx={{
        paddingLeft: '180px',
        backgroundColor: 'rgb(239, 239, 239)',
      }}>
      {props.children}
    </Box>
  );
};

/*
  A view for form elements; is used on the register visitor
  form. It adds left, top and bottom padding and sets the maximum
  width for form elements.
*/
function LcDarkFormView(props) {
 //     bgcolor="tertiary.main"  
  return (
    <Box
      sx={{
        paddingLeft: '180px',
        paddingTop: '40px',
        paddingBottom: '40px',
        backgroundColor: 'rgb(239, 239, 239)',
      }}>
    <Box
    sx={{  width: '50%',}}>
    {props.children}
    </Box>
    </Box>
  );
};

/* --------------------------------------------------------------- */

export {
  LcDarkView,
  LcDarkFormView
};

/* --------------------------------------------------------------- */
