/* ------------------------------------------------------- */

import { LcClock } from 'lib/ui/LcClock';
import { LcHomeButton } from 'lib/ui/LcButton';

/* ------------------------------------------------------- */

function SdSidePanelPage(props) {

  let css = [ 'lc-sidepanel-page', props?.className ];
  
  return (
    <div className={css.join(' ')}>
      {props.children}
    </div>
  );
};

/* ------------------------------------------------------- */

function SdSidePanelBar(props) {

  let css = [ 'lc-sidepanel-bar', props?.className ] ;

  return (
    <div
      className={css.join(' ')}
    >
      {props?.showHomeButton &&
       <LcHomeButton title="Home" onClick={props?.onHomeClicked} />
      }

      {props?.title &&
       <h2 className="lc-sidepanel-title">{props.title}</h2>
      }

      {props.children}
      
      <LcClock />
      
    </div>
  );
};

/* ------------------------------------------------------- */

function SdSidePanelContent(props) {
  
  let css = [ 'lc-sidepanel-content' ];
  
  return (
    <div className={css.join(" ")}>
      {props.children}
    </div>
  );
}

/* ------------------------------------------------------- */

export {
  SdSidePanelPage,
  SdSidePanelBar,
  SdSidePanelContent,
};

/* ------------------------------------------------------- */

