/* --------------------------------------------------------------- */

const LcActionTypes = {
  SET_VISITOR_DATA: 'setvisitordata',
  UNSET_VISITOR_DATA: 'unsetvisitordata',
  SET_EVENT_ID: "seteventid",
};

/* --------------------------------------------------------------- */

/* 
  Set the complete visitor data; see `LcReducer` for the
  `visitor` field which is what we expect.
*/
const LcActions = {

  setVisitorData: (data) => {
    return {
      type: LcActionTypes.SET_VISITOR_DATA,
      data: data,
    };
  },

  unsetVisitorData: () => {
    return {
      type: LcActionTypes.UNSET_VISITOR_DATA,
    };
  },

  setEventId: (id) => {
    return {
      type: LcActionTypes.SET_EVENT_ID,
      data: id,
    }
  },

};

/* --------------------------------------------------------------- */

export {
  LcActionTypes,
  LcActions
}

/* --------------------------------------------------------------- */


