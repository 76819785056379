/*

  LEAD CAPTURE FILTER
  ====================

  GENERAL INFO:

    A drop down that is used as filter in the Service Desk
    application.
    
  REFERENCES:

    [0]: https://mui.com/api/select/

*/
/* --------------------------------------------------------------- */


import { useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

/* --------------------------------------------------------------- */

/*
  InputLabel:
    - Text that you see when nothing has been selected
    - Text which moves and shrinks a bit up when you have selected something. 

  Select:
    - The text you see when you have selected an item.
  

    https://github.com/mui-org/material-ui/issues/14260 "Create your own input"

*/

/* --------------------------------------------------------------- */

function LcFilterItem(props) {

  const onItemClicked = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (props.onClick) {
      props.onClick(props);
    }
  };
  
  return (
    <li onClick={onItemClicked}>{props.title}</li>
  );
};

/* --------------------------------------------------------------- */

/*
  Generates a menu list with options; The list can go "down" or
  up, depending on the `direction` property. By default the
  direction down.
*/
function LcFilter(props) {

  const [is_open, setIsOpen] = useState(false);

  /* Handle header click. */
  const onFilterHeaderClicked = (ev) => {
    setIsOpen(!is_open);
  };

  /* Handle item click. */
  const onFilterItemClicked = (item) => {
    props.onChange(item);
    setIsOpen(!is_open);
  };

  const onFilterModalClicked = () => {
    setIsOpen(false);
  };

  /* Collect the classes that are used to toggle open/closed. */
  let header_classes = ['lc-filter'];
  let modal_classes = ['lc-filter-modal'];
  let content_classes = ['lc-filter-content'];

  /* When `direction` is `up` we show the options above the filter. */
  if (props.direction
      && "up" == props.direction)
  {
    content_classes.push("up");
  }

  if (props.className) {
    header_classes.push(props.className);
  }
  
  if (true == is_open) {
    header_classes.push('open');
    modal_classes.push('open');
  }
  
  let title = props.title;
  if (null != props.selectedIndex
      && props.items
      && props.selectedIndex < props.items.length)
  {
    title = props.items[props.selectedIndex].title;
  }

  /*
    When the filter popup is open, we also open a modal which
    takes is put as an overlay over the whole site page. When the
    user clicks outsite the popup, we will handle the click and
    close the filter.
   */
  return (
    <>
      <div className={modal_classes.join(" ")} onClick={onFilterModalClicked}></div>
      <div className={header_classes.join(" ")} onClick={onFilterHeaderClicked}>
        
        <div className="lc-filter-header">
          <strong className="lc-filter-title">{title}</strong>
          <KeyboardArrowDownOutlinedIcon fontSize="small" className="lc-filter-tick" />
        </div>
        
        <ul className={content_classes.join(" ")}>
          {
            props.items.map((el, dx) => {
              return (<LcFilterItem key={dx} {...el} index={dx} onClick={onFilterItemClicked} />)
            })
          }
          {
            props.onShowAllClicked &&
              <li className="lc-filter-show-all" onClick={props.onShowAllClicked}>Show all</li>
          }
        </ul>
      </div>
    </>
  );
};

/* --------------------------------------------------------------- */

export default LcFilter;

/* --------------------------------------------------------------- */
