/* --------------------------------------------------------------- */

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

/* --------------------------------------------------------------- */

/*

  - `helperText` , attribute of the `TextField, text underneath
    the element; maybe for error messages?

    [0]: https://mui.com/api/text-field/ 

  - InputLabelProps: placeholder in the textfield

*/
function LcTextInput(props) {

  /*
    '& input:hover + fieldset': {
    border: `none`
    }
  */

  /* Only use the helper text when there is an error. */
  let helper_text = '';
  if (true == props.error
      && props.helperText)
  {
    helper_text = props.helperText.trim();
  }

  let asterisk = '';
  if (props?.isRequired) {
    asterisk = '*';
  }

  return (
    <TextField
      autoComplete="no"
      name={props.name}
      label={props.title}
      helperText={helper_text}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      variant="outlined"
      placeholder={props.placeholder +asterisk}
      margin="normal"
      error={props.error}
      fullWidth
      InputLabelProps={{
        sx: {
          color: 'rgb(153, 153, 153)', /* Placeholder */
          display: 'none',
        }
      }}
      inputProps={{
        sx: {
          paddingLeft: '35px', /* Padding for placeh0older. */
        } 
      }}
      InputProps={{
        sx: {
          borderRadius: '30px',
          backgroundColor: 'white',
        }
      }}
      sx={{
        '& input + fieldset': {
          borderColor: 'whitesmoke',
          boxShadow: '0px 0px 8px 4px rgba(0,0,0,0.03);',
          border: 'none',
        },
        '& .Mui-error fieldset': {
          border: '2px solid rgb(240, 75, 65) !important',
        },
        '& + legend': {
          display: 'none',
        },
        '& fieldset': {
        },
        ':hover fieldset': {
          borderColor: 'red',
        },
        '& input': {
          color: 'black',
        },
      }}

    />
  );
};

/* --------------------------------------------------------------- */

function LcDarkTextInput(props) {
  
  return (
    <TextField
      name={props.name}
      label={props.title}
      helperText={props.helperText}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      variant="outlined"
      placeholder={props.placeholder}
      margin="normal"
      error={!!props.error}
      fullWidth
      InputLabelProps={{
        sx: {
          color: 'rgb(153, 153, 153)', /* Placeholder */
          display: 'none',
        }
      }}
      inputProps={{ /* There is a `inputProps` and `InputProps` */
        maxLength: props.maxLength,
      }}
      InputProps={{
        sx: {
          borderRadius: '7px',
          backgroundColor: 'rgb(7, 30, 43)',
        }
      }}
      sx={{
        '& input + fieldset': {
          borderColor: 'rgba(156, 219, 217, 0.4)',
        },
        '& + legend': {
          display: 'none',
        },
        '& fieldset': {
        },
        ':hover fieldset': {
        },
        '& input': {
          color: 'white',
        },
        '& textarea': {
          color: 'white',
          fontSize: '30px',
          lineHeight: '35px',
        },
      }}
      
      {...props}
    />
  )
};

/* --------------------------------------------------------------- */

export {
  LcTextInput,
  LcDarkTextInput,
}

/* --------------------------------------------------------------- */
