/* --------------------------------------------------------------- */

import Box from '@mui/material/Box';

/* --------------------------------------------------------------- */

/* 
  Only contains left padding; it's up to the content owner to set
  the vertical padding and margins. This is for example used to
  contain the line on the register visitor form.
*/
function LcLightView(props) {

  return (
    <Box
      sx={{
        paddingLeft: '180px',
        paddingRight: props.paddingRight || 0,
      }}>
      {props.children}
    </Box>
  );
};

/*
  A view for form elements; is used on the register visitor
  form. It adds left, top and bottom padding and sets the maximum
  width for form elements.
*/
function LcLightFormView(props) {
    return (
      <Box
        className={props.className}
        sx={{
          paddingLeft: '180px',
          paddingTop: '40px',
          paddingBottom: '60px',
        }}>
        
        <Box
          sx={{width: '50%'}}
        >
          {props.children}
        </Box>
    </Box>
  );
};

/* --------------------------------------------------------------- */

export {
  LcLightView,
  LcLightFormView,
};

/* --------------------------------------------------------------- */
