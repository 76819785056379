/* --------------------------------------------------------------- */

import { LcApi } from 'lib/core/LcApi';
import AvatarPlaceholder from 'img/avatar-placeholder.jpg';

/* --------------------------------------------------------------- */

function LcRepresentativeTopics(props) {

  if (!props.topics
      || 0 == props.topics.length)
  {
    return null;
  }

  return (
    <ul className="lc-repr-topic-list">
      {
        props.topics.map((el, dx) => {
          return (<li key={dx} className="lc-repr-topic"> {el.title}</li>)
        })
      }
    </ul>
  );
};

/* Just the body with the image, name and topics */
function LcRepresentativeInfo(props) {

  let img_url = LcApi.getServerUrl() + props.imageLink;
  let avail_classes = ['lc-repr-state'];
  let avail_title = "Unavailable";
  
  if (true == props.available) {
    avail_classes.push("lc-repr-available");
    avail_title = "Available";
  }

  if (!props.imageLink) {
    img_url = AvatarPlaceholder;
  }

  return (
    <>
      <div className="lc-repr-col-img">
        <img className="lc-repr-avatar" src={img_url} /> 
        <div className={avail_classes.join(" ")}>{avail_title}</div>
      </div>
      <div className="lc-repr-col-info">
        <h4 className="lc-repr-name">{props.firstName}&nbsp;{props.lastName}</h4>
        { props.department && <h5 className="lc-repr-department">{props.department.title}</h5>}
        <LcRepresentativeTopics topics={props.topics} />
      </div>
    </>
  );
};

/* Represents a representative in the match list */
function LcRepresentative(props) {

  const onClick = (ev) => {
    if (props.onClick) {
      props.onClick(props);
    }
  };

  let classes = ["lc-repr"];
  if (true == props.isSelected) {
    classes.push("active");
  }
  
  return (
    <div className={classes.join(" ")} onClick={onClick}>
      <LcRepresentativeInfo {...props} />
    </div>
  );
};

/* --------------------------------------------------------------- */

export {
  LcRepresentativeInfo,
  LcRepresentative,
}

/* --------------------------------------------------------------- */
