/* --------------------------------------------------------------- */

import { LcActions, LcActionTypes } from 'lib/core/LcActions';

/* --------------------------------------------------------------- */

const LcInitialState = {
  event_id: 1,
  language: "en",
  visitor: {
    first_name: null,
    last_name: null,
    company: null,
    job_title: null,
    email: null,
    country: null,
    phone: null,
    receive_news: false,
    industries: [],
    products: [],
    products_level2: [],
    products_level3: [],
  },
};

/* --------------------------------------------------------------- */

const lcReducer = (state, action) => {

  switch(action.type) {
      
    case LcActionTypes.SET_VISITOR_DATA: {
      console.log("<<>> Set visitor data.");
      return { ...state, visitor: { ...action.data} };
    }

    case LcActionTypes.UNSET_VISITOR_DATA: {
      console.log("<<>> Unset visitor data");
      return { ...state, visitor: { ...LcInitialState.visitor }};
    }

    case LcActionTypes.SET_EVENT_ID: {
      console.log("<<>> Set event id: ", action.data);
      return {...state, event_id: action.data }
    }
      
  };

  return { ...state};
};

/* --------------------------------------------------------------- */

export {
  LcInitialState,
  lcReducer,
};

/* --------------------------------------------------------------- */
