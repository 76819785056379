/* --------------------------------------------------------------- */

import LcStack from 'lib/ui/LcStack';
import { LcButton, LcSecondaryButton } from 'lib/ui/LcButton';
import { ReactComponent as AlertIcon } from 'img/modal-alert-icon.svg';
import { ReactComponent as NotificationIcon } from 'img/notification-icon.svg';

/* --------------------------------------------------------------- */

/* Draws a background ^.^ */
function LcModal(props) {

  return (
    <div className="lc-modal" onClick={props.onClick}>
      {props.children}
    </div>
  );
}

/* --------------------------------------------------------------- */

function LcModalConfirmUnsavedChanges(props) {

  const onYesClicked = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (props.onYesClicked) {
      props.onYesClicked();
    }
  };

  const onCancelClicked = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (props.onCancelClicked) {
      props.onCancelClicked();
    }
  };

  return (
    <LcModal onClick={props.onCancelClicked}>
      <div className="lc-modal-confirm">
        <AlertIcon />
        <div className="lc-modal-confirm-text">
          <div><strong>Are you sure</strong></div>
          <span>you want to leave and discard the data put in?</span>
        </div>
        <LcStack direction="row" spacing={2}>
          <LcButton title="Yes" onClick={onYesClicked} />
          <LcSecondaryButton className="lc-modal-cancel-button" title="Cancel" onClick={onCancelClicked} textColor="black" />
        </LcStack>
      </div>
    </LcModal>
  );
};

/* --------------------------------------------------------------- */

function LcModalNotificationSent(props) {

  const onOkClicked = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (props.onOkClicked) {
      props.onOkClicked();
    }
  };

  return (
    <LcModal onClick={props.onOkClicked}>
      <div className="lc-modal-confirm">
        <NotificationIcon />
        <div className="lc-modal-confirm-text">
          <span>A </span><strong>notification<br /></strong>
          <span>has been</span><strong> sent</strong>
        </div>
        <LcButton title="OK" onClick={onOkClicked} />
      </div>
    </LcModal>
  );
};

/* --------------------------------------------------------------- */

export {
  LcModal,
  LcModalConfirmUnsavedChanges,
  LcModalNotificationSent,
};

/* --------------------------------------------------------------- */
