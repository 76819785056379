/*
  ---------------------------------------------------------------

                       ██████╗  ██████╗ ██╗  ██╗██╗     ██╗   ██╗
                       ██╔══██╗██╔═══██╗╚██╗██╔╝██║     ██║   ██║
                       ██████╔╝██║   ██║ ╚███╔╝ ██║     ██║   ██║
                       ██╔══██╗██║   ██║ ██╔██╗ ██║     ██║   ██║
                       ██║  ██║╚██████╔╝██╔╝ ██╗███████╗╚██████╔╝
                       ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝ ╚═════╝ 

                                                    www.roxlu.com
                                            www.twitter.com/roxlu
  
  ---------------------------------------------------------------
  
  TREE VIEW
  ==========

  GENERAL INFO:

    This compoment allows you to create simple trees. This was
    used to render the checkboxes for the products of interests.
    You pass an array with `id` and `parentid` fields which are
    used to build the hierarchy.

    ```js
     const tree = [
       {
         id: 0,
         parentid: null,
         title: "Chapter 1",
       },
       {
         id: 1,
         parentid: null,
         title: "Chapter 2",
       },
       {
         id: "1.1",
         parentid: 0,
         title: "Paragraph 1.1",
       },
       {
         id: "1.2",
         parentid: 0,
         title: "Paragraph 1.2",
       },
       {
         id: "1.3",
         parentid: 0,
         title: "Paragraph 1.3",
       },
       {
         id: "1.3.1",
         parentid: "1.3",
         title: "Paragraph 1.3.1",
       },
       {
         id: "2.1",
         parentid: 1,
         title: "Paragraph 2.1",
       },
     ];
   ```

   Then you create a custom component that is used to render 
   each node, e.g.:

   ```js

    function CbItem(item) {
      return (
        <FormControlLabel control={<Checkbox />} label={item.title} />
      );
    };
    
     ...
     return (
       <LcTreeView
          items={tree}
          itemComponent={(item) => { return ( <CbItem {...item}></CbItem>) }}
        />
        
     );

   ```


*/
/* --------------------------------------------------------------- */

import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

/* --------------------------------------------------------------- */

function LcTreeItem(props) {

  return (
    <div className='lc-tree-item'>
      {props.itemComponent(props.item)}      
      {props.children}
    </div>
  );
}

/* --------------------------------------------------------------- */

function LcTreeView(props) {

  function findRoots(items) {
    return items.filter((item) => {
      if (null == item.parentid) {
        return item;
      }
    });
  };

  function getChildren(items, parent) {
    return items.filter((item) => {
      if (item.parentid == parent.id) {
        return item;
      }
    });
  };

  function renderChildren(items, parent) {

    let children = getChildren(items, parent);

    return (
      <LcTreeItem
        key={parent.id}
        item={parent}
        itemComponent={props.itemComponent}
      >
        {
          children.map((child) => {
            return renderChildren(items, child);
          })
        }
      </LcTreeItem>
    );
  };
  
  let roots = findRoots(props.items);
  let els = roots.map((rootNode) => {
    return renderChildren(props.items, rootNode);
  });

  return els;
};

/* --------------------------------------------------------------- */

export default LcTreeView;

/* --------------------------------------------------------------- */
