/* --------------------------------------------------------------- */

import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

import LcPageHeader from 'lib/ui/LcPageHeader';
import LcStack from 'lib/ui/LcStack';
import LcPageLine from 'lib/ui/LcPageLine';
import LcFilter from 'lib/ui/LcFilter';
import { LcClock } from 'lib/ui/LcClock';
import { LcLargeTitle, LcColumnTitle } from 'lib/ui/LcTitles';
import { LcHomeButton, LcButton, LcIconButton, LcSecondaryButton } from 'lib/ui/LcButton';
import { LcDarkView, LcDarkFormView } from 'lib/ui/LcDarkView';
import { LcLightView, LcLightFormView, } from 'lib/ui/LcLightView';
import { LcRepresentative } from 'lib/ui/LcRepresentative';
import { LcColumnLogo } from 'lib/ui/LcLogo';
import { LcCircularProgress } from 'lib/ui/LcCircularProgress';
import { LcModalConfirmUnsavedChanges, LcModalNotificationSent } from 'lib/ui/LcModal'
import { useLcFetchRegistrationDataMatch, useLcSaveVisitor } from 'lib/core/LcApi';
import { lcGetAllRepresentativeMatchUsers, lcGetRepresentativeMatchLocations } from 'lib/core/LcUtils';
import { ReactComponent as FindRepresentativeIcon } from 'img/find-representative-icon-right.svg';
import { useLcContext } from 'lib/core/LcContext';
import { SdSidePanelPage, SdSidePanelBar, SdSidePanelContent } from 'ui/SdSidePanel';
import LcRepresentativeMatchLogo from 'img/representative-match-oq-logo.png'

/* --------------------------------------------------------------- */

function SdLocation(props) {

  const onClick = (ev) => {
    if (props.onClick) {
      props.onClick(props, ev);
    }
  };

  let classes = ['lc-location'];
  if (true == props.active) {
    classes.push('active');
  }
  
  return (
    <div className={classes.join(" ")} onClick={onClick}>
      <span>{props.location}</span>
    </div>
  );
};

/* --------------------------------------------------------------- */

/*
  @props

   - representatives (array):         Array with the representatives; each representative has an field `visible` which is used to toggle the visibility. 
   - onClick (function):              Function that we call when the user clicks a rep.
   - selectedIndex (integer|null):    The index of the selected representative. 
  
*/
function SdRepresentativeList(props) {

  return (
    <div className="lc-repr-representatives-list">
      {
        props.representatives.map((rep, dx) => {

          if (false == rep.visible) {
            return null;
          }

          return (
            <LcRepresentative
              {...rep}
              key={dx}
              index={dx}
              isSelected={props.selectedIndex == dx}
              onClick={props.onClick}
            />
          )
        })
      }
    </div>
  );

};

/* --------------------------------------------------------------- */

function SdRepresentativeMatchScreen() {

  const ctx = useLcContext();
  const history = useHistory();
  const [data, error] = useLcFetchRegistrationDataMatch();
  const [visitor_is_saving, visitor_has_error, saveVisitor] = useLcSaveVisitor();
  const [show_notification_modal, setShowNotificationModal] = useState(false);
  const [show_save_modal, setShowSaveModal] = useState(false);
  const [selected_department_index, setSelectedDepartmentIndex] = useState(null);
  const [selected_representative_index, setSelectedRepresentativeIndex] = useState(null);
  const [selected_availability_index, setSelectedAvailabilityIndex] = useState(null);
  const [selected_location_index, setSelectedLocationIndex] = useState(null);

  /* Create availability filter items. */
  let filt_available = { id: 1, title: "Available", value: true };
  let filt_unavailable = { id: 2, title: "Unavailable", value: false };
  let availables = [ filt_available, filt_unavailable ];

  /* Create departments filter items. */
  let departments = [];
  if (data && data.departments) {
    departments = data.departments;
  }

  /* Get representatives */
  let users = [];
  if (data && data.users) {
    users = lcGetAllRepresentativeMatchUsers(data);
  }

  let locations = [];
  if (data && data.locations) {
    locations = lcGetRepresentativeMatchLocations(data);
  }

  /* --------------------------------------------------------------- */
  /* APPLY FILTERS TO THE REPRESENTATIVES                            */
  /* --------------------------------------------------------------- */

  if (users) {
    
    let filt_dep = null;
    let filt_avail = null;
    
    if (null != selected_department_index) {
      filt_dep = departments[selected_department_index].id;
    }

    if (null != selected_availability_index) {
      filt_avail = availables[selected_availability_index].value;
    }
    
    for (var i = 0; i < users.length; ++i) {

      let user = users[i];
      user.visible = false;

      /* Filter on the selected department. */
      if (null != filt_dep
          && null != user.department
          && user.department.id != filt_dep)
      {
          continue;
      }

      if (null != filt_dep
          && !user.department)
      {
        continue;
      }

      /* Filter on availability */
      if (null != filt_avail
          && user.available != filt_avail)
      {
        continue;
      }

      user.visible = true;
    }
  }

  /* When the previously selected reprsentative was filtered away, deselect it. */
  if (null != selected_representative_index
      && false == users[selected_representative_index].visible)
  {
    setSelectedRepresentativeIndex(null);
  }
  
  /* --------------------------------------------------------------- */
  /* SET LOCATIONS FOR SELECTED REPRESENTATIVE                       */
  /* --------------------------------------------------------------- */

  if (null != selected_location_index) {

    for (var i = 0; i < locations.length; ++i) {
      locations[i].active = false
    }
    
    locations[selected_location_index].active = true;
  }

  /* --------------------------------------------------------------- */

  const onSaveAndSendNotificationClicked = async () => {

    let rep_id = null;
    if (null != selected_representative_index) {
      rep_id = users[selected_representative_index].id;
    }

    let loc_id = null;
    if (null != selected_location_index) {
      loc_id = locations[selected_location_index].id;
    }
    
    let visitor = {
      ...ctx.state.visitor,
      user: rep_id,
      user_location: loc_id,
    };
    
    let result = await saveVisitor(visitor);
    if (false == result.isValid()) {
      console.error("@todo unhandled situation; maybe show popup/error messaage?");
      history.push("/"); /* @todo still going back to the home screen for now. */
      return;
    }

    /* When saved and we've sent the notification show the modal. */
    setShowNotificationModal(true);
  };

  const onRegisterOnlyClicked = async () => {

    let visitor = {
      ...ctx.state.visitor,
    };
    
    let result = await saveVisitor(visitor);
    if (false == result.isValid()) {
      console.error("@todo unhandled situation; maybe show popup/error messaage?");
      return;
    }
    
    history.push("/"); /* @todo still going back to the home screen for now. */
  };

  const onDepartmentFilterChange = (item) => {
    setSelectedDepartmentIndex(item.index);
  };

  const onAvailabilityFilterChange = (item) => {
    setSelectedAvailabilityIndex(item.index);
  };

  const onLocationClicked = (item) => {
    setSelectedLocationIndex(item.index);
  };

  const onRepresentativeClicked = (rep) => {
    setSelectedRepresentativeIndex(rep.index);
  };

  const onNotificationModalClicked = () => {
    history.push("/");
  };

  const onModalSaveYesClicked = () => {
    history.push("/");
  };

  const onModalSaveCancelClicked = () => {
    setShowSaveModal(false);
  };

  const onHomeClicked = (ev) => {
    ev.preventDefault();
    setShowSaveModal(true);
  };

  const onDepartmentFilterShowAllClicked = () => {
    setSelectedDepartmentIndex(null);
  };

  const onAvailableFilterShowAllClicked = () => {
    setSelectedAvailabilityIndex(null);
  };

  /* --------------------------------------------------------------- */

  /* When one of these are true we disable the send button. */
  let save_disabled = false;
  save_disabled |= visitor_is_saving;
  save_disabled |= (selected_representative_index == null);
  save_disabled |= (selected_location_index == null);

  /* --------------------------------------------------------------- */

  function getContentRight() {
    return (
      <>
        <div className="lc-repr-representatives">
        
          <LcStack direction="row">

            <LcFilter
              title="Department"
              items={departments}
              selectedIndex={selected_department_index}
              onChange={onDepartmentFilterChange}
              onShowAllClicked={onDepartmentFilterShowAllClicked}
            />
            
            <LcFilter
              title="Availability"
              items={availables}
              selectedIndex={selected_availability_index}
              onChange={onAvailabilityFilterChange}
              onShowAllClicked={onAvailableFilterShowAllClicked} 
            />
          </LcStack>

          <SdRepresentativeList
            representatives={users}
            selectedIndex={selected_representative_index}
            onClick={onRepresentativeClicked}
          />

          <LcPageLine />
          
        </div>
        <div className="lc-repr-locations">

          <div className="lc-repr-locations-list">
            <LcLargeTitle>Locations</LcLargeTitle>
            {/* <Fade duration="220" direction="up" cascade > */}
            {
              locations.map((loc, dx) => {
                return (<SdLocation key={dx} {...loc} onClick={onLocationClicked} />)
              })
            }
            {/* </Fade> */}
          </div>

          
          <div className="lc-repr-buttons-container">
            <LcIconButton
              className="lc-repr-save-and-register-button lc-button"
              disabled={!!save_disabled}
              title="Save and send notification"
              onClick={onSaveAndSendNotificationClicked}
              endIcon={<FindRepresentativeIcon />}
            />
            <LcSecondaryButton
              title="Register only"
              className="lc-repr-reg-only-button lc-button"
              disabled={!!save_disabled}
              onClick={onRegisterOnlyClicked} 
            />
            {visitor_is_saving &&
             <LcCircularProgress />
            }
          </div>
        </div>
      </>
    );
  };

  /* ------------------------------------------------------- */

  return (
    <SdSidePanelPage className="lc-match-page">
      { show_notification_modal &&
        <LcModalNotificationSent onOkClicked={onNotificationModalClicked}/>
      }
      { show_save_modal &&
        <LcModalConfirmUnsavedChanges
          onYesClicked={onModalSaveYesClicked}
          onCancelClicked={onModalSaveCancelClicked}
        />
      }
      <SdSidePanelBar
        showHomeButton={true}
        onHomeClicked={onHomeClicked}
        title={`Representative\nMatch`}
      >
      </SdSidePanelBar>
      <SdSidePanelContent>
        { getContentRight() }
      </SdSidePanelContent>
    </SdSidePanelPage>
  );
};

/* --------------------------------------------------------------- */

export default SdRepresentativeMatchScreen;

/* --------------------------------------------------------------- */
