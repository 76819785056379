/*
  LEAD CAPTURE CHECKBOX
  =====================

  GENERAL INFO:
  
    You can use custom SVGs as icons with MUI. You have to make
    sure that you cleanup the SVGs that you e.g. export from
    Adobe XD.  I've used [this website][0] which works very well.

    On Linux you can also use [SVGCleaner][1] form the command line:

    ```bash
    pacman -S svgcleaner
    svgcleaner in.svg out.svg
    ```

  REFERENCES:

    [0]: https://jakearchibald.github.io/svgomg/  "SVGOMG"
    [1]: https://github.com/RazrFalcon/SVGCleaner "SVG Cleaner"

*/
/* --------------------------------------------------------------- */

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as CheckBoxIconOn } from 'img/checkbox-on.svg';
import { ReactComponent as CheckBoxIconOff } from 'img/checkbox-off.svg';

/* --------------------------------------------------------------- */

//              itemComponent={(item) => { return ( <LcCheckbox title={item.title} onChange={onIndustriesCheckboxChange}></LcCheckbox>) }}
function LcCheckbox(props) {

  const onChange = (ev) => {
    if (props.onChange) {
      props.onChange(props, ev);
    }
  };

  let classes = ["lc-checkbox"];
  if (props.className) {
    classes.push(props.className);
  }
  
  return (
    <FormControlLabel
      className={classes.join(" ")}
      control={<Checkbox
                 checkedIcon={<CheckBoxIconOn />}
                 icon={<CheckBoxIconOff />}
                 checked={props.checked}
                 onChange={onChange}
               />
              }
      label={props.title}
      sx={{...props.sx}}
    />
  );
};

/* --------------------------------------------------------------- */

export default LcCheckbox;

/* --------------------------------------------------------------- */
