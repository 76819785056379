/*

  LEAD CAPTURE SELECT
  ====================

  REFERENCES:

    [0]: https://mui.com/api/select/

*/
/* --------------------------------------------------------------- */

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from '@mui/material/FormHelperText';
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as SelectBoxIcon } from 'img/selectbox-icon.svg';
import { ReactComponent as MeetingRoomSelectBoxIcon } from 'img/meeting-form-select-icon.svg';

/* --------------------------------------------------------------- */

/*

  While working on this select I had to deal with controlled and
  uncontrolled state in regards to ReactJS. When you want to set/get
  the value from a component using JS/React code, you're basically
  making it a controlled component. By setting the following 
  properties you make it a controlled component:

    - value
    - onChange

  IMPORTANT: 

      There is a hidden semantics here... when you've got a
      controlled component, e.g. when you storing it's value in a
      `useState` variable, then the value cannot be `null`. 

      It's common to have `null` values when dealing with database
      data and a trick you can use is:

      <LcSelect
        value={state_variable || ''}
      />
 
      Now, when `state_variable` is null, it will pass an empty string
      as it's value, which is "valid" for React.

  REFERENCES:

    [0]: https://github.com/facebook/react/issues/11417
  
*/
function LcSelect(props) {

  let asterisk = '';
  if (props?.isRequired) {
    asterisk = '*';
  }

  return (
    <FormControl
      error={props.error}
      margin="normal"
      fullWidth={true}
      sx={{
        'fieldset': {
          border: 'none',
          boxShadow: '0px 0px 8px 4px rgba(0,0,0,0.03);',
        },
        '& .Mui-error fieldset': {
          border: '2px solid rgb(240, 75, 65) !important',
        },
      }}
    >
      {/* <InputLabel>Country*</InputLabel> */}
      <Select
        name={props.name}
        value={props.value}
        displayEmpty={true}
        onChange={props.onChange}
        onBlur={props.onBlur}

        IconComponent={() => <Box sx={{padding:'10px', pointerEvents:'none', position: 'absolute', right: '20px',}}><SelectBoxIcon/></Box> }
        sx={{
          borderColor: 'red',
          borderRadius: '30px',
          backgroundColor: 'white',
          paddingRight: '30px',
          paddingLeft: '22px', /* Placeholder padding. */
        }}
      >
        { props.placeholder &&
          
          <MenuItem value="">
            <Box sx={{
              color: 'rgb(153, 153, 153)',  /* Placeholder color */
            }}>
              {props.placeholder + asterisk}
            </Box>
          </MenuItem>
        }

        {
          props.items
            && props.items.map((item, dx) => {
              return (
                <MenuItem
                  key={dx}
                  value={item.id}
                  {...item}
                >
                {item.title}
                </MenuItem>
              )
            })
        }
        
      </Select>
      
      { props.error && props.helperTextt && <FormHelperText>{props.helperText}</FormHelperText> }

    </FormControl>
    
  );
};

/* --------------------------------------------------------------- */

/*

  See this for how to create an outlined element:

      https://stackoverflow.com/questions/67064682/mui-outlined-select-label-is-not-rendering-properly
      
      - InputLabel: just the tiny text you see, 
      - MenuProps -> MenuListProps: popup menu/list of items.

  See this Stack Overflow for info how fix the arrow issue that's not clickable:

      https://stackoverflow.com/questions/54929613/select-is-not-working-onclick-iconcomponentdropdown-arrow-in-react-material-ui
  
  
*/
function LcDarkSelect(props) {

  return (
    <FormControl
      error={props.error}
      margin="normal"
      variant="outlined"
      fullWidth={true}
      className={props.className}
      sx={{
        'fieldset': {
          border: '1px solid rgba(156, 219, 217, 0.4)',
        },
      }}
    >
      <Select
        name={props.name}
        value={props.value}
        displayEmpty={true}
        IconComponent={() => <Box sx={{padding:'10px', pointerEvents:'none', position: 'absolute', right: '5px',}}><MeetingRoomSelectBoxIcon/></Box> }
        onChange={props.onChange}
        onBlur={props.onBlur}
        sx={{
          backgroundColor: 'rgb(7, 30, 43)',
          borderRadius: '7px', /* Border radius of select itself. */
          color: 'white', /* Text color of selected item. */
        }}
      >
        { props.placeholder &&
          
          <MenuItem value="">
            <Box sx={{
              color: 'rgb(153, 153, 153)',  /* Placeholder color */
            }}>
              {props.placeholder}
            </Box>
          </MenuItem>
        }
        
        {
          props.items && props.items.map((item, dx) => {

            /* @todo I'm leaving this code here for now; this allows you to change the color of some text. */
            let style = {};
            /*
            if (true == item.disabled) {
              style.color = 'red';
            }
            */

            return (
              <MenuItem
                key={dx}
                value={item.id}
                {...item}
                sx={style}
                disabled={!!item.disabled}
              >
                {item.title}
              </MenuItem>
            )
          })
        }
        
      </Select>
      
      { props.error && props.helperTextt && <FormHelperText>{props.helperText}</FormHelperText> }
      
    </FormControl>
  );
};

/* --------------------------------------------------------------- */

export {
  LcSelect,
  LcDarkSelect,
}

/* --------------------------------------------------------------- */
