/* --------------------------------------------------------------- */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

/* ------------------------------------------------------- */

import React, { useState, useEffect, useRef } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import { LcTextInput } from 'lib/ui/LcTextInput';
import { LcSelect } from 'lib/ui/LcSelect';
import LcTreeView from 'lib/ui/LcTreeView';
import LcCheckbox from 'lib/ui/LcCheckbox';
import LcStack from 'lib/ui/LcStack';
import LcPageHeader from 'lib/ui/LcPageHeader';
import LcPageLine from 'lib/ui/LcPageLine';
import { LcLargeTitle, LcColumnTitle } from 'lib/ui/LcTitles';
import { LcClock } from 'lib/ui/LcClock';
import { LcHomeButton, LcButton, LcIconButton, LcSecondaryButton } from 'lib/ui/LcButton';
import { LcDarkView, LcDarkFormView } from 'lib/ui/LcDarkView';
import { LcLightView, LcLightFormView, } from 'lib/ui/LcLightView';
import { LcPageLogo } from 'lib/ui/LcLogo';
import { LcCircularProgress } from 'lib/ui/LcCircularProgress';
import { LcProgressBar } from 'lib/ui/LcProgress'
import { LcModalConfirmUnsavedChanges, LcModalNotificationSent } from 'lib/ui/LcModal'
import { useLcFetchRegistrationFormData, useLcSaveVisitor, LcApi } from 'lib/core/LcApi';
import { lcGetRegistrationFormCountries, lcGetRegistrationFormIndustries, lcGetRegistrationFormProductsOfInterest, jss } from 'lib/core/LcUtils';
import { useLcContext } from 'lib/core/LcContext';
import { ReactComponent as FindRepresentativeIcon } from 'img/find-representative-icon-right.svg';
import { ReactComponent as CloseIcon } from 'img/close-icon.svg';
import { ReactComponent as BadgeScanShapeIcon } from 'img/badge-scan-shape-icon.svg';
import { SdBadgeScan } from 'core/SdBadgeScan';
import { SdSidePanelPage, SdSidePanelBar, SdSidePanelContent } from 'ui/SdSidePanel';
import LcRegisterLogo from 'img/register-visitor-oq-logo.png'
import LcConfig from 'core/LcConfig';

/* --------------------------------------------------------------- */

const RegisterValidationSchemaManualInput = Yup.object().shape({
  first_name: Yup
    .string()
    .nullable()
    .required(),
  last_name: Yup
    .string()
    .nullable()
    .required(),
  company: Yup
    .string()
    .nullable()
    .required(),
  job_title: Yup
    .string()
    .nullable()
    .required(),
  email: Yup
    .string()
    .nullable()
    .required(" ")
    .email("Please fill in a valid email address"),
  country: Yup
    .string()
    .nullable()
    .required(),
});

const RegisterValidationSchemaScan2Lead = Yup.object().shape({
  first_name: Yup
    .string()
    .nullable()
    .required(),
  last_name: Yup
    .string()
    .nullable()
    .required(),
  email: Yup
    .string()
    .nullable()
    .required(" ")
    .email("Please fill in a valid email address"),
});

/* ------------------------------------------------------- */

function getValidationSchema(flow) {

  if (!flow || "manual" == flow) {
    return RegisterValidationSchemaManualInput;
  }

  return RegisterValidationSchemaScan2Lead;
}

function isFieldRequired(desc, field) {

  if (!desc['fields'][field]) {
    return false;
  }

  let tests = desc.fields[field]['tests'];
  if (!tests) {
    return false;
  }
  
  let has_required = tests?.find?.(test => test.name == "required");
  if (has_required) {
    return true;
  }

  return false;
}

/* --------------------------------------------------------------- */

function SdIndustryCheckbox(props) {

  const info = props.info;
  
  return (
    <div key={info.id} className="form-row checkbox">
      <input type="checkbox" id={info.id}/>
      <label htmlFor={info.id}>{props.info.title}</label>
    </div>
  );
};

/* --------------------------------------------------------------- */
/*

  This function will recursively fill the `result` array with the
  children and all grand children for the given parentId. This
  function is used to deselect all children fo the checkbox tree.
  
*/
function getAllChildrenOfProductsOfInterestProduct(allProducts, parentId, result)  {
  
  if (!allProducts) {
    return;
  }
  
  if (!parentId) {
    return;
  }

  let children = allProducts.filter((prod) => prod.parentid == parentId);

  for (var i = 0; i < children.length; ++i) {
    let child = children[i];
    result.push(child);
    getAllChildrenOfProductsOfInterestProduct(allProducts, child.id, result);
  }
};

function getAllParentsOfProductsOfInterestProduct(allProducts, product, result) {

  if (!allProducts) {
    return;
  }
  
  if (!product) {
    return;
  }

  if (!product.parentid) {
    return;
  }

  if (!result) {
    return;
  }

  let parent = allProducts.find((prod) => prod.id == product.parentid);
  if (!parent) {
    return;
  }

  result.push(parent);

  if (parent.parentid) {
    getAllParentsOfProductsOfInterestProduct(allProducts, parent, result);
  }
};

/* --------------------------------------------------------------- */

function SdBadgeScanRow(props) {

  if (!props?.title) {
    return null;
  }

  return (
    <span className="lc-badge-row">{props.title}</span>
  );
};

function SdBadgeScanDetails(props) {

  let values = props?.values;

  /* Countries is special: convert ID to title. */
  let countries = props?.countries;
  let country = null;
  if (countries && values?.country) {
    country = countries.find(el => el.id == values.country);
    country = country?.name;
  }

  return (
    <div className="lc-badge-rows">
      <SdBadgeScanRow title={values?.first_name} />
      <SdBadgeScanRow title={values?.last_name} />
      <SdBadgeScanRow title={values?.company} />
      <SdBadgeScanRow title={values?.job_title} />
      <SdBadgeScanRow title={values?.email} />
      <SdBadgeScanRow title={country} />
      <SdBadgeScanRow title={values?.phone} />
      <div className="lc-badge-details-edit" onClick={props.onEditClick} >edit</div>
    </div>
  );
};

function SdBadgeScanPrompt(props) {
  return (
    <>
      <div className="lc-badge-req-prompt">
        <BadgeScanShapeIcon />
        <div className="lc-badge-req-title">
          <span>Scan the </span>
          <strong>visitor badge</strong>
        </div> 
      </div>
    </>
  );
}

/* --------------------------------------------------------------- */

function SdRegisterVisitorScreen(props) {

  const ctx = useLcContext();
  const history = useHistory();
  const [data, error] = useLcFetchRegistrationFormData();
  const [edit_badge_details, setEditBadgeDetails] = useState(false);
  const [is_calling_badge_api, setIsCallingBadgeApi] = useState(false);
  const [has_scan_error, setHasScanError] = useState(false);
  const [visitor_info, setVisitorInfo] = useState(null);
  const [is_visitor_info_valid, setIsVisitorInfoValid] = useState(false);
  const [initial_touched, setInitialTouched] = useState();
  const [initial_errors, setInitialErrors] = useState();
  const [visitor_is_saving, visitor_has_error, saveVisitor] = useLcSaveVisitor();
  const [show_representative_screen, setShowRepresentativeScreen] = useState(false);
  const [show_save_modal, setShowSaveModal] = useState(false);
  const [form_data, setFormData] = useState({
    industries: [],
    products: [],
    products_level2: [],
    products_level3: [],
  });
  const [qr_code, setQrCode] = useState(null);
  const [badge_code, setBadgeCode] = useState(null); /* The code that was used to retrieve data from the API. We have to reset `qr_code` as it's unset after scanning so we can do another scan. */
  const [badge_json, setBadgeJson] = useState(null);

  let countries = lcGetRegistrationFormCountries(data);
  let industries = lcGetRegistrationFormIndustries(data);
  let products = lcGetRegistrationFormProductsOfInterest(data);

  useEffect(() => {
    
    if (true == LcConfig.use_badge_scan) {
      SdBadgeScan.init();
      SdBadgeScan.onCodeFound = (uid) => {
        setQrCode(uid);
      };
      return () => {
        console.log("Disconnecting from websocket.");
        SdBadgeScan.shutdown();
      };
    }
    
  }, []);

  useEffect(() => {

    if (!qr_code) {
      return;
    }

    if (!formik) {
      console.log("We've received a QR code, but our `formik` var is null.");
      return;
    }

    setIsCallingBadgeApi(true);
    setHasScanError(false);
    
    LcApi
      .getScan2LeadInfo(ctx.state.event_id, ctx.state.language, qr_code)
      .then(res => {
        
        if (!res
            || res?.hasApiError())
        {
          /* @todo set/show error. */
          console.log("An error occured while trying to get the scan2lead code.");
          setHasScanError(true);
          return false;
        }

        let data = res.getData();
        if (!data) {
          /* @todo set/show error. */
          console.log("Failed to get the visitor data.");
          setHasScanError(true);
          return false;
        }

        if (!data?.success) {
          /* @todo show error */
          console.log("Failed to get visitor data successfully.");
          setHasScanError(true);
          return false;
        }
        
        let vis = data.visitor;
        
        /* Map the Scan2Lead data to our data structure. */
        let mapped = {
          first_name: vis?.firstName,
          last_name: vis?.lastName,
          company: vis?.company,
          job_title: vis?.profession,
          email: vis?.email,
          country: vis?.countryIso,
          phone: vis?.phone,
        };

        /* Get the country id and details. */
        let country_iso = mapped?.country?.toLowerCase();
        if (country_iso) {
          let details = countries.find(el => el.code == country_iso);
          mapped.country = details?.id
        }

        setVisitorInfo(mapped);
        setBadgeScanVisitorInfo(mapped);
        setBadgeCode(qr_code);
        setBadgeJson(JSON.stringify(vis));
      })
      .catch(err => {
        console.log("An unhandled error occured: ", err);
        setHasScanError(true);
      })
    .finally(() => {
      //setQrCode(null);
      setIsCallingBadgeApi(false);    
    });

  }, [qr_code]);

  /* When visitor info changes we validate */
  useEffect(() => {

    let info = getValidationSchema(props?.flow)
        .validate(visitor_info, {abortEarly: false})
        .then(res => {
          setIsVisitorInfoValid(true);
          setInitialErrors({});
          setInitialTouched({});
        })
        .catch(err => {

          /* Extract the errors. */
          let initial_errors = {};
          let initial_touched = {};

          /* We must convert the "el.path" to string otherwise it's an object. */
          err.inner.forEach(el => {
            initial_errors[el.path + ""] = el.message + "";
            initial_touched[el.path + ""] = true;
          });

          setIsVisitorInfoValid(false);
          setInitialErrors(initial_errors);
          setInitialTouched(initial_touched);
        });
    
  }, [visitor_info]);

  /* Copy industry state */
  for (var i = 0; i < form_data.industries.length; ++i) {

    let id = form_data.industries[i];
    let industry = industries.find((el) => el.id == id);

    if (!industry) {
      console.error("Failed to find the selected industry");
      continue;
    }
    
    industry.checked = true;
  }

  /* Copy products state */
  var sel_levels = [form_data.products, form_data.products_level2, form_data.products_level3];
  for (var i = 0; i < products.length; ++i) {
    let prod = products[i];
    var id_parts = prod.id.split(".");
    var part_level = id_parts[0];
    var part_id = id_parts[1];
    var is_id_selected = sel_levels[part_level].find(id => part_id == id);
    prod.checked = !!is_id_selected;
  }

  const handleFormikOnSubmit = (formikValues, ev) => {

    /* Save the user data in the context. */
    let visitor = {
      ...formikValues,
      ...form_data,
      type: "SDMI",
    };

    /* When we're using the scan2lead flow add a bit more info. */
    if ("scan" == props?.flow) {
      visitor.type = "SDS2L";
      visitor.badge_code = badge_code;
      visitor.badge_json = badge_json;
    }

    /* Does the user only wants to register the visitor? */
    if ("register" == formikValues.route) {
      saveVisitor(visitor);
      history.push("/");
      return;
    }

    /* ..or do we need to move to the other page before saving? */
    ctx.dispatch(ctx.actions.setVisitorData(visitor));
    if (formikValues.route == "find") {
      history.push("/representative");
    }
    
    return false;
  };

  /*
     When we scan a badge and retrieve information about the
     visitor, we map the received data into the same field=value
     pairs that we use for a manual input.  Then we set each
     field.
   */
  function setBadgeScanVisitorInfo(info) {
    
    for (var field in info) {

      var value = info[field];
      if (!value) {
        continue;
      }
      
      formik.setFieldValue(field, value);
    }
  };

  /*
    Initial values which are purely handled by formik; we're
    handling the industries and products differently as they have
    a silly structure. Because we have to dynamically change the
    values of the form we also store most values in our own
    `form_data`.
  */
  let initial_values = {
    first_name: null,
    last_name: null, 
    company: null,
    job_title: null, 
    email: null,
    country: null,
    phone: null,
    receive_news: false,
    industries: form_data?.industries,
    products: form_data?.products,
    products_level2: form_data?.products_level2,
    products_level3: form_data?.products_level3,
  };

  /* Used while debugging and during development. */
  const setDebugDummyValues = () => {

    formik.setFieldValue("first_name", "roxlu");
    formik.setFieldValue("last_name", "roxlu");
    formik.setFieldValue("company", "roxlu");
    formik.setFieldValue("job_title", "roxlu");
    formik.setFieldValue("email", "diederick@roxlu.com");
    formik.setFieldValue("country", 1);
    formik.setFieldValue("phone", "+316123456789");

    formik.setTouched({
      first_name: true,
      last_name: true,
      company: true,
      job_title: true,
      email: true,
      country: true,
      phone: true,
      receive_news: true,
    });
  };

  //setDebugDummyValues();

  const formik = useFormik({
    initialValues: initial_values,
    enableReinitialize: false,
    initialTouched: initial_touched,
    initialErrors: initial_errors,
    onSubmit: handleFormikOnSubmit,
    validationSchema: getValidationSchema(props?.flow),
  });

  const onIndustriesCheckboxChange = (item, ev) => {

    industries[item.index].checked = !item.checked;

    /* Update the selected industries values. */
    let selected = [];
    for (var i = 0; i < industries.length; ++i) {
      let ind = industries[i];
      if (ind.checked) {
        selected.push(ind.id);
      }
    }

    let indus_ids = {
      industries: selected,
    };

    /* 
      We set the formik values because we want to know if the
      form was made dirty. Formik checks it's initial values with
      the current once and therefore we have to pass the current
      ones.
    */
    formik.setFieldValue("industries", indus_ids);
    
    setFormData({...form_data, ...indus_ids});
  };

  const onProductsOfInterestCheckboxChange = (item, ev) => {

    /* Update the checks state so we can recreate the array below. */
    let is_checked = !item.checked;
    products[item.index].checked = is_checked;
    
    /* When deselected, we also have to deselect all children. */
    if (false == is_checked) {
      let all_children = [];
      getAllChildrenOfProductsOfInterestProduct(products, item.id, all_children);
      for (var i = 0; i < all_children.length; ++i) {
        all_children[i].checked = false;
      }
    }

    /* When selected also select all parents */
    if (true == is_checked) {
      let all_parents = [];
      getAllParentsOfProductsOfInterestProduct(products, products[item.index], all_parents);
      for (var i = 0; i < all_parents.length; ++i) {
        all_parents[i].checked = true;
      }
    }

    /* Rebuild the selected level IDs. */
    let sel_levels = [[],[], []];
    for (var i = 0; i < products.length; ++i) {
      
      var prod = products[i];
      if (false == prod.checked) {
        continue;
      }
      
      var id_parts = prod.id.split(".");
      var level = id_parts[0];
      var id = parseInt(id_parts[1]);

      sel_levels[level].push(id);
    }

    let prod_ids = {
      products: sel_levels[0],
      products_level2: sel_levels[1],
      products_level3: sel_levels[2],
    };

    /* 
      We copy the values into formik because we want to know if
      the form was made dirty.
    */
    formik.setFieldValue('products', prod_ids);

    setFormData({
      ...form_data,
      ...prod_ids,
    });
  };

  const onPressedFindRepresentative = (ev) => {
    setShowRepresentativeScreen(!show_representative_screen);
    return false;
  };

  /*
    Because we have two different routes we have to take after
    submitting we have to store where the user came from; we use
    a fake `route` value for this.
   */
  const onSubmitClicked = (name, ev) => {
    ev.preventDefault();
    formik.setFieldValue("route", name);
    formik.handleSubmit();
  };

  const onModalYesClicked = () => {
    history.push("/");
  };

  const onModalCancelClicked =() => {
    setShowSaveModal(false);
  };

  const onHomeClicked = (ev) => {

    /* Show popup for badge scan flow. */
    if ("scan" == props?.flow
        && null != badge_code)
      {
        ev.preventDefault();
        setShowSaveModal(true);
      }
        
    if (false == formik.dirty) {
      return;
    }
    
    ev.preventDefault();
    setShowSaveModal(true);
  };

  const onReceiveNewsClicked = () => {

    formik.setFieldValue('receive_news', !formik.values.receive_news);
    
    setFormData({
      ...form_data,
      receive_news: !formik.values.recieve_news
    });
  };

  /*
    Disable the submit:

    - when we've received visitor info (from scan2lead for example),
      and that info is valid, the user can still edit the
      form.
      
    - when the user edits the form and there is visitor info,
      the received visitor info will say valid, but the current
      formik values may not. 

  */
  let disable_submit = !(formik.isValid && formik.dirty);
  if (visitor_info && is_visitor_info_valid) {
    disable_submit = false;
    if (formik.dirty && !formik.isValid) {
      disable_submit = true;
    }
  }

  let panel_title = `Manual\nregistration`;
  if ("scan" == props?.flow) {
    panel_title = `Visitor badge\nscan`;
  }
  
  let schema_desc = getValidationSchema(props?.flow).describe();

  const getPersonalDetailFormElements = () => {

    /* Don't show the form elements when scanning a badge OR only when requested. */
    if ("scan" == props?.flow
        && false == edit_badge_details)
    {
      return null;
    }

    return (
      <>
        <LcTextInput
          name="first_name"
          placeholder="First name"
          value={formik.values.first_name || ''}
          error={formik.errors.first_name && formik.touched.first_name}
          isRequired={isFieldRequired(schema_desc, "first_name")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        
        <LcTextInput
          name="last_name"
          placeholder="Last name"
          value={formik.values.last_name || ''}
          error={formik.errors.last_name && formik.touched.last_name}
          isRequired={isFieldRequired(schema_desc, "last_name")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <LcTextInput
          name="company"
          placeholder="Company"
          value={formik.values.company || ''}
          error={formik.errors.company && formik.touched.company}
          isRequired={isFieldRequired(schema_desc, "company")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <LcTextInput
          name="job_title"
          placeholder="Job title"
          value={formik.values.job_title || ''}
          error={formik.errors.job_title && formik.touched.job_title}
          isRequired={isFieldRequired(schema_desc, "job_title")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <LcTextInput
          name="email"
          placeholder="Email"
          value={formik.values.email || ''}
          error={formik.errors.email && formik.touched.email}
          helperText={formik.errors.email}
          isRequired={isFieldRequired(schema_desc, "email")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        { /* note: see LcSelect.js for info about why we use `form_data.country || ''` */ }
        <LcSelect
          placeholder="Country"
          name="country"
          value={formik.values.country || ''}   
          items={countries}
          error={formik.errors.country && formik.touched.country}
          isRequired={isFieldRequired(schema_desc, "country")}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
        />

        <LcTextInput
          name="phone"
          placeholder="Phone"
          value={formik.values.phone || ''}
          error={formik.errors.phone}
          isRequired={isFieldRequired(schema_desc, "phone")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </>
    );
  };

  const onBadgeEditClick = () => {
    setEditBadgeDetails(true);
  };

  const getBadgeScan = () => {

    if ("scan" != props?.flow) {
      return null;
    }

    if (true == edit_badge_details) {
      return null;
    }

    let css_progress = ['lc-badge-api-progress'];
    if (true == is_calling_badge_api) {
      css_progress.push('lc-badge-api-progress-visible');
    }

    return (
      <>
        <div className="lc-badge-req-container">
          {visitor_info && 
           <SdBadgeScanDetails
             countries={countries}
             values={visitor_info}
             onEditClick={onBadgeEditClick}
           />
          }
          {!visitor_info &&
           <>
             <SdBadgeScanPrompt />
             <LcProgressBar className={css_progress.join(" ")} />
           </>
          }
        </div>
        {has_scan_error &&
         <span className="lc-register-required-message">Sadly we could not automatically register your details at this time.</span>
        }
      </>
    );
  };

  return (
    <SdSidePanelPage className="lc-register-page">

      { (true == show_save_modal) &&
        <LcModalConfirmUnsavedChanges
          onYesClicked={onModalYesClicked}
          onCancelClicked={onModalCancelClicked}
        />
      }

      <SdSidePanelBar
        className="lc-register-sidebar"
        title={panel_title}
        showHomeButton={true}
        onHomeClicked={onHomeClicked}
      />
      
      <SdSidePanelContent>

        <form onSubmit={formik.handleSubmit}>

          <LcLightFormView>
            <LcLargeTitle>Contact information</LcLargeTitle>
            { getBadgeScan() }
            { getPersonalDetailFormElements() }
          </LcLightFormView>

          <LcLightView paddingRight="740px">
            <LcPageLine sx={{marginBottom: '20px'}}/>
          </LcLightView>
          
          <LcLightFormView className="lc-register-visitor-buttons" >

            <LcCheckbox
              title="Would you like OQ to send you news and updates?"
              checked={formik.values.receive_news}
              value={formik.values.receive_news}
              onChange={onReceiveNewsClicked}
              sx={{marginBottom:'40px'}}
            >
            </LcCheckbox>

          </LcLightFormView>

          <LcStack direction="column" spacing={2} className="lc-register-buttons">
            {disable_submit && 
             <span className="lc-register-required-message">Please fill out all required fields</span>
            }
            <LcIconButton
              title="Find a representative"
              className="lc-button  lc-register-button"
              onClick={(ev) => onSubmitClicked("find", ev)}
              endIcon={<FindRepresentativeIcon />}
              disabled={visitor_is_saving || disable_submit}
            />
            <LcSecondaryButton
              title="Register only"
              className="lc-button"
              disabled={visitor_is_saving || disable_submit}
              onClick={(ev) => onSubmitClicked("register", ev)}
            />
          </LcStack>

          <LcDarkFormView>
            <LcLargeTitle>Products of interest</LcLargeTitle>
            <LcTreeView
              items={products}
              itemComponent={(item) => {
                return (
                  <LcCheckbox
                    checked={item.checked}
                    id={item.id}
                    index={item.index}
                    title={item.title}
                    onChange={onProductsOfInterestCheckboxChange}
                  >
                  </LcCheckbox>
                )
              }}
            />
          </LcDarkFormView>
          <LcLightFormView>
            <LcLargeTitle>Industries of interest</LcLargeTitle>
            <LcTreeView
              items={industries}
              itemComponent={(item) => {
                return (
                  <LcCheckbox
                    checked={item.checked}
                    id={item.id}
                    index={item.index}
                    title={item.title}
                    onChange={onIndustriesCheckboxChange}
                  >
                  </LcCheckbox>
                )
              }}
            />
          </LcLightFormView>
        </form>
      </SdSidePanelContent>
    </SdSidePanelPage>
  );
};

/* --------------------------------------------------------------- */

export default SdRegisterVisitorScreen;

/* --------------------------------------------------------------- */
