/* --------------------------------------------------------------- */

/*
  This file is used by `lib/core/LcApi.js`; as we're sharing the 
  library with different apps, each app needs to create it's own 
  config.

  Production: https://leadcapture-api.oq.com
  Staging:    https://oq-leadcapture-api.staging.alligence.com
  Asana task: https://app.asana.com/0/1200941836607829/1199969853244253

  Staging web:    https://oq-servicedesk.staging.alligence.com/
  Production web: https://oq-servicedesk.alligence.com/
   
*/
const LcConfig = {
  server_url: 'https://xeikon-leadcapture-api.staging.alligence.com',  /* staging */
  //server_url: 'https://xeikon-leadcapture-api.staging.alligence.com',  /* production */
  base_url: 'api/v1',
  api_key: '3960b5079e914b8b8a164e984eb14f7b',
  event_title: "Drupa 2024",
  use_badge_scan: false,
};

/* --------------------------------------------------------------- */

export default LcConfig;

/* --------------------------------------------------------------- */
