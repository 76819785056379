/* --------------------------------------------------------------- */

import { useState, useEffect } from 'react';
import { ReactComponent as ClockIcon } from 'img/white-clock-icon.svg';

/* --------------------------------------------------------------- */

function LcClock(props) {

  const [time_str, setTimeString] = useState(0);

  useEffect(() => {

    let interval_handle = null;
    
    function onTimer() {

      let date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();

      if (hours < 10) {
        hours = "0" +hours;
      }
      if (minutes < 10) {
        minutes = "0" +minutes;
      }

      setTimeString(hours +":" +minutes);
    };
    
    function unsubscribe() {
      if (null != interval_handle) {
        window.clearInterval(interval_handle);
        interval_handle = null;
      }
    };

    interval_handle = window.setInterval(onTimer, 2000);

    onTimer();
    
    return unsubscribe;
  }, []);
  

  return (
    <div className="lc-clock">
      <ClockIcon />
      <span>{time_str}</span>
    </div>
  );
   
};

/* --------------------------------------------------------------- */

export {
  LcClock,
}

/* --------------------------------------------------------------- */
