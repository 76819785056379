/* --------------------------------------------------------------- */

import Box from '@mui/material/Box';

/* --------------------------------------------------------------- */

function LcPageLine(props) {
  
  return (
    <Box
      sx={{
        width: '100%',
        height: '1.5px',
        backgroundColor: 'rgb(204, 204, 204)',
        marginBottom: '60px',
        ...props.sx,
      }}>
      {props.children}
    </Box>
  );
};

/* --------------------------------------------------------------- */

export default LcPageLine;

/* --------------------------------------------------------------- */
