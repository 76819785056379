/*
    ---------------------------------------------------------------

                         ██████╗  ██████╗ ██╗  ██╗██╗     ██╗   ██╗
                         ██╔══██╗██╔═══██╗╚██╗██╔╝██║     ██║   ██║
                         ██████╔╝██║   ██║ ╚███╔╝ ██║     ██║   ██║
                         ██╔══██╗██║   ██║ ██╔██╗ ██║     ██║   ██║
                         ██║  ██║╚██████╔╝██╔╝ ██╗███████╗╚██████╔╝
                         ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝ ╚═════╝ 

                                                      www.roxlu.com
                                              www.twitter.com/roxlu
    
    ---------------------------------------------------------------


    UTILS
    =====

    GENERAL INFO:

      This file contains utils that are used through the
      application. These are mostly little helper functions that
      can be used on multiple screens. We try to prefix the
      functions with `lc` to indicate their from the Lead Capture
      app.
*/

/* --------------------------------------------------------------- */

import moment from 'moment';
import _ from 'lodash';

/* --------------------------------------------------------------- */

/*
  This function generates an array with all the possible time
  slots in a day. The slots objects that we create here contain
  the base attributes. Currently we only have slots every 30
  minutes and a meeting can be booked over a multiple slots.
*/
function lcGenerateAllTimeSlots() {

  let ranges = [];
  for (var i = 0; i < 24; ++i) {

    let start_from = i +"00";
    let start_until = i +"30";
    let start_from_title = i + ":00";
    let start_until_title = i + ":30";
    let end_from = start_until;
    let end_until = (i+1) + "00";
    let end_from_title = start_until_title;
    let end_until_title = (i+1) + ":00";
    
    if (i < 10) {
      start_from = "0" +start_from;
      start_until = "0" +start_until;
      start_from_title = "0" +start_from_title;
      start_until_title = "0" +start_until_title;
      end_from = "0" +end_from;
      end_from_title = "0" +end_from_title;
    }

    if ((i + 1) < 10) {
      end_until = "0" +end_until;
      end_until_title = "0" +end_until_title;
    }
    
    ranges.push({
      i: (i * 2) + 0,
      is_booked: false,
      start: start_from,
      start_title: start_from_title,
      end: start_until,
      end_title: start_until_title,
    });
    
    ranges.push({
      i: (i * 2) + 1,
      is_booked: false,
      start: end_from,
      start_title: end_from_title,
      end: end_until,
      end_title: end_until_title,
    });
  }

  //jss("RANGES", ranges);
  
  return ranges;
}

/* Get slots, starting with `start` and up. */
function lcGetTimeSlotsRangeStartingWithCode(slots, start) {

  let did_start = false;
  let range = [];
  
  for (var i = 0; i < slots.length; ++i) {

    var slot = slots[i];
    
    if (false == did_start) {
      if (slot.start == start) {
        did_start = true;
      }
    }

    if (false == did_start) {
      continue;
    }

    range.push(slot);
  }

  return range;
}

/* 
  Get slots, up until the `start` value matches the given
  `end`. This means that if you give and `end` value of `1730`,
  that the last slot will have a start time of `1730`. Use the
  `lcGetTimeSlotsRangeEndingWithCodeIncludingEnd()` to get one
  where the last start time is the end time of the event.

                                                          1830-1900
    ┌─────┐  ┌─────┐  ┌─────┐  ┌─────┐  ┌─────┐  ┌─────┐  ┌─────┐
    │     │  │     │  │     │  │     │  │     │  │     │  │     │
    │  1  │  │  2  │  │  3  │  │  4  │  │  5  │  │  6  │  │  7  │
    │     │  │     │  │     │  │     │  │     │  │     │  │     │
    └─────┘  └─────┘  └─────┘  └─────┘  └─────┘  └─────┘  └─────┘
   │                                                     │
   ├─────────────────────────────────────────────────────┤
   │                                                     │

   0900                                               1830


   This function DOES NOT return box 7. 

*/
function lcGetTimeSlotsRangeEndingWithCodeExcludingEnd(slots, end) {

  let range = [];
  
  for (var i = 0; i < slots.length; ++i) {

    var slot = slots[i];
    range.push(slot);

    if (slot.start == end) {
      return range;
    }
  }

  return range;
}

/*

  This function will return all slots up and including the given
  `end` code. This can be used to get slots where the `start`
  code of the end time of the event is also included. So if you
  event end time slot is the 18:00-18:30 slot, then we will
  return the slots where the last slot has a start code of
  `1830`. The `end` value of this last slot isn't very usefull;
  but having this array is good to create ranges; this is for
  example used on the meeting form.


                                                          1830-1900
    ┌─────┐  ┌─────┐  ┌─────┐  ┌─────┐  ┌─────┐  ┌─────┐  ┌─────┐
    │     │  │     │  │     │  │     │  │     │  │     │  │     │
    │  1  │  │  2  │  │  3  │  │  4  │  │  5  │  │  6  │  │  7  │
    │     │  │     │  │     │  │     │  │     │  │     │  │     │
    └─────┘  └─────┘  └─────┘  └─────┘  └─────┘  └─────┘  └─────┘
   │                                                     │
   ├─────────────────────────────────────────────────────┤
   │                                                     │

   0900                                               1830


   This function also return box 7. 


*/
function lcGetTimeSlotsRangeEndingWithCodeIncludingEnd(slots, end) {

  let range = [];
  let found_end = false;
  
  for (var i = 0; i < slots.length; ++i) {

    var slot = slots[i];
    range.push(slot);
    
    if (found_end) {
      return range;
    }
    
    if (slot.start == end) {
      found_end = true;
    }
  }

  return range;
}

/* Get slots, up until the `end` value matches the given `end`. */
function lcGetTimeSlotsRangeEndingWithCode(slots, end) {

  let range = [];
  
  for (var i = 0; i < slots.length; ++i) {
    var slot = slots[i];
    range.push(slot);
    if (slot.end == end) {
      return range;
    }
  }

  return range;
}

/*
  We expect to receive an array like: `["1100","1130","1200"]`  and 
  we return the min and max slots.

  ```js
    {
      min_slot: {
      }
      max_slot: {
      }
    }
  ```
*/

function lcGetTimeSlotMinMaxFromSlotCodesArray(slots) {


  if (!slots) {
    return { min_slot: null, max_slot: null };
  }

  let all_slots = lcGenerateAllTimeSlots();
  let sorted = slots.sort();
  let slot_first = sorted[0];
  let slot_last = sorted[0];
  
  if (sorted.length > 1) {
    slot_last = sorted[sorted.length - 1];
  }

  let min_slot = all_slots.find((slot) => slot.start == slot_first);
  let max_slot = all_slots.find((slot) => slot.start == slot_last);

  return {
    min_slot: min_slot,
    max_slot: max_slot,
  };
};


/* 
  Formats the duration string for two slots. The minSlot and
  maxSlot define the range.
*/
function lcGetTimeSlotDurationStringForMinMaxSlots(minSlot, maxSlot) {
  return minSlot.start_title +" - " +maxSlot.end_title;
}

/* 
  This function is used to show the full duration of a meeting in
  the Service Desk Applicatio When the user is looking at a
  booked slot.
*/
function lcGetTimeSlotsDurationStringFromSlotCodesArray(slots) {

  let result = lcGetTimeSlotMinMaxFromSlotCodesArray(slots);
  if (!result || !result.min_slot || !result.max_slot) {
    return '';
  }
  
  return lcGetTimeSlotDurationStringForMinMaxSlots(result.min_slot, result.max_slot);
};

/* Get the slots array for starting with `start` up until `end`,
  where the last `start` code is the one before `end`. E.g. when
  `end` is 17:30, then the last slot will have a start code of
  `1700`.
*/
function lcGetTimeSlotsForRangeExcludingEnd(start, end) {
  let ranges = null;
  ranges = lcGenerateAllTimeSlots();
  ranges = lcGetTimeSlotsRangeStartingWithCode(ranges, start);
  ranges = lcGetTimeSlotsRangeEndingWithCodeExcludingEnd(ranges, end);

  //jss(`RANGE ${start} - ${end}`, ranges);
  
  return ranges;
}

function lcGetTimeSlotsForRangeIncludingEnd(start, end) {
  let ranges = null;
  ranges = lcGenerateAllTimeSlots();
  ranges = lcGetTimeSlotsRangeStartingWithCode(ranges, start);
  ranges = lcGetTimeSlotsRangeEndingWithCodeIncludingEnd(ranges, end);
  return ranges;
}

/* 

   Get the slots array starting with `start` up until `end`.
   When looking at the example below, when you pass "0900" for
   `start` and "1830" for `end`, you'll get an arary where the
   last `slot.start == 1800` and `slot.end == 1830`.

      
                              1800-1830      1830-1900
            ┌─────┐  ┌─────┐  ┌─────┐        ┌─────┐
            │     │  │     │  │     │        │     │
            │  1  │  │  2  │  │  3  │        │  4  │
            │     │  │     │  │     │        │     │
            └─────┘  └─────┘  └─────┘        └─────┘
           │                              │
           ├──────────────────────────────┤
           │                              │
       
           0900                        1830
       

   
   This function returns the codes 1, 2, 3 when the
   given end is 1800.


*/
function lcGetTimeSlotsForRange(start, end) {
  let ranges = null;
  ranges = lcGenerateAllTimeSlots();
  ranges = lcGetTimeSlotsRangeStartingWithCode(ranges, start);
  ranges = lcGetTimeSlotsRangeEndingWithCode(ranges, end);
  return ranges;
}

function lcGetTimeSlotCodesForRange(start, end) {
  
  let ranges = lcGetTimeSlotsForRange(start, end);
  let codes = ranges.map((slot) => {
    return slot.start;
  });

  return codes;
}

function lcGetTimeSlotCodesForRangeExcludingEnd(start, end) {
  
  let ranges = lcGetTimeSlotsForRangeExcludingEnd(start, end);
  let codes = ranges.map((slot) => {
    return slot.start;
  });

  return codes;
}

/*

  This function will return the next time slot object which comes
  after the given slot. We expect that the given slot was from
  the `generateAllTimeSlots()` function.
  
*/
function lcGetTimeSlotAfterSlot(slot) {

  if (!slot) { 
    return false;
  }

  let slots = lcGenerateAllTimeSlots();
  if (!slots) {
    console.error("Failed to generate all time slots.");
    return false;
  }

  let slot_after = slots.find((s) => s.start == slot.end);
  return slot_after;
};

/* Get the time slot code, e.g. 0930, 1000, etc. for the current time. */
function lcGetCurrentTimeSlotCode() {

  let now = new Date();
  let hours = moment(now).format("HH");
  let minutes = moment(now).minutes();

  if (minutes >= 30) {
    minutes = 30;
  }
  else {
    minutes = "00";
  }
  
  return hours +"" +minutes;
};

/*
  Ok, this is quite a function name :^).. but this function is 
  used on the Meeting Room app to show how long a room is still
  available relative to a selected time slot. 

    ┌─────┐  ┌─────┐  ┌─────┐  ┌─────┐ ┌─────┐  ┌─────┐
    │     │  │     │  │     │  │     │ │     │  │     │
    │  1  │  │  2  │  │  3  │  │  4  │ │  5  │  │  6  │
    │  x  │  │     │  │     │  │     │ │  x  │  │     │
    └─────┘  └─────┘  └─────┘  └─────┘ └─────┘  └─────┘

  When the user clicks slot nr. 3 or nr. 4 we will return 
  slot number 2 as this is the most left still available
  slot. 

  @param (array):   slots, array generated by `lcGenerateAllTimeSlotsCodes()`
  @param (integer): the slot index from where you want to start. 
  
*/
function lcGetMostLeftAvailableTimeSlotForSlotIndex(slots, slotIndex) {

  if (!slots || false == Array.isArray(slots)) {
    return false;
  }

  let prev_slot = null;
  for (var i = 0; i < slots.length; ++i) {
    
    if (slots[i].index > slotIndex) {
      break;
    }
    
    if (true == slots[i].is_booked) {
      prev_slot = null;
      continue;
    }
    
    if (null == prev_slot) {
      prev_slot = slots[i];
    }
  }


  return prev_slot;
}

/* 
  Similar to `lcGetMostLeftAvailableTimeSlotForSlotIndex()`, but
  now we move into the -> direction instead.
*/
function lcGetMostRightAvailableTimeSlotForSlotIndex(slots, slotIndex) {
  
  let next_slot = null;
  
  for (var i = 0; i < slots.length; ++i) {
    
    if (slots[i].index < slotIndex) {
      continue;
    }
    
    if (true == slots[i].is_booked) {
      break;
    }
    
    next_slot = slots[i];
  }

  return next_slot;
}

/*
  This function is used by the Meeting Room app to generate the
  duration string that shows how long a meeting room is still
  availble based on the given slotIndex.

  @param (array):   slots, array generated by `lcGenerateAllTimeSlotsCodes()`
  @param (integer): the slot index from where you want to start. 

*/
function lcGetDurationStringFromAvailableRangeForSlotIndex(slots, slotIndex) {

  if (!slots || false == Array.isArray(slots)) {
    return '';
  }
  
  let prev_slot = lcGetMostLeftAvailableTimeSlotForSlotIndex(slots, slotIndex);
  if (!prev_slot) {
    console.error("Failed to get the most left slot.");
    return '';
  }

  let next_slot = lcGetMostRightAvailableTimeSlotForSlotIndex(slots, slotIndex);
  if (!next_slot) {
    console.error("Failed to get the most right slot.");
    return '';
  }

  return lcGetTimeSlotDurationStringForMinMaxSlots(prev_slot, next_slot);
};

/* --------------------------------------------------------------- */

/*
  This function will collect the data which is required to generate
  the meeting room schedule overview. The API result contains the
  necessary data, but it uses `id`s which means we have to build up
  a usable array ourself. 

  The `data` you pass into this function is the data which you get
  from `/api/v1/servicedesk/meeeting/overview` or 
  form the `/api/v1/meetingroom/meeting/overview` end points.
  See the following example of what we expect:

  ```js
        {
          "eventDates": [
            {
              "id": 0,
              "date": "string",
              "meetingStartTimeSlot": "string",
              "meetingEndTimeSlot": "string"
            }
          ],
          "meetingRooms": [
            {
              "id": 0,
              "title": "string"
            }
          ],
          "meetings": [
            {
              "id": 0,
              "eventDate": 0,
              "meetingRoom": 0,
              "timeSlots": [
                "string"
              ],
              "description": "string",
              "bookedBy": "string"
            }
          ]
        }
  ```

  We return an array with meetings where the `timeSlots`
  member is sorted, e.g.:

  ```js
   {
     "eventDate": 1,
     "meetingRoom": 1,
     "timeSlots": [
       "0730",
       "0800",
       "0830",
       "0900",
       "0930"
     ]
   }
  ```
*/
function lcExtractMeetingsForEventDateIdAndRoomId(data, dateId, roomId) {

  let meetings = [];

  if (!data
      || !data.meetings
      || data.meetings.length == 0)
  {
    return meetings;
  }

  for (var i = 0; i < data.meetings.length; ++i) {
    
    var m = data.meetings[i];
    if (m.meetingRoom != roomId) {
      continue;
    }

    if (m.eventDate != dateId) {
      continue;
    }

    var slots = m.timeSlots.sort((a, b) => { return a.start < b.start; });

    meetings.push({
      ...m,
      timeSlots: [...slots]
    });
  }

  // console.log("============================== ROOM: " +roomId +"===============================");
  // console.log(JSON.stringify(meetings, null, 2));
  // console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++");
  
  return meetings;
}

/*
  This function will generate the array with room objects which
  are used by the room schedule to display the slots and the
  possible meetings for each slot. The REST api gives us a
  slightly different result as you can see in the comments above
  for `lcExtractMeetingsForEventDateIdAndRoomId()`. 

  We return an object that looks like:

  ```js
  {
    room: {
      id: 0,            // room id
      title: "Room 1"   // room title
    },
    slots: [            // array with all the slots 
       {
         i: 0,          // slot counter/index
         start: "0000"  // start slot time
         end: "0300"    // end slot time
         booked: false  // wether the slot was booked or not.
       },
    ]
  }
  ```

  NOTES:

    At some point I ran into an issue with a booking and the 
    start/end indices regarding the very first timeslot. I used
    a somewhat faster (not measured though) approach but rewrote
    to make the code simpler and correct. See this link and the
    `if (false)` part for what I used previously.

    https://gist.github.com/roxlu/dd8c765b137404899c8a758a989f08cd

*/
function lcGetRoomWithAllSlotsForEventDateIdAndRoomId(data, eventDateId, roomId) {

  let room = lcGetRoomById(data, roomId);
  if (!room) {
    console.error("Cannot get the room data as we didn't find the room.");
    return false;
  }

  /* 
    The `slots` array is contains the default slot info for the given 
    `eventDateId`; below, we're going to set the fields to the correct
    values; e.g. making the `is_booked` field `true` or `false` wether
    there is a meting. 
    */
  let slots = lcGetAllTimeSlotsForEventDateId(data, eventDateId);
  if (!slots) {
    console.error("Cannot get the room data with time slots as we failed to get the time slots.");
    return false;
  }

  /*
    A meeting looks like:

       {
          "eventDate": 1,
          "meetingRoom": 1,
          "timeSlots": [
            "0730",
            "0800",
            "0830",
            "0900",
            "0930"
          ]
       }
   */
  let meetings = lcExtractMeetingsForEventDateIdAndRoomId(data, eventDateId, roomId);

  for (let i = 0; i < meetings.length; ++i) {

    let m = meetings[i];

    /* Make all the slots for this meeting `booked` */
    for (let i = 0; i < m.timeSlots.length; ++i) {

      let meeting_slot = m.timeSlots[i];
      let dx = slots.findIndex((slot) => slot.start == meeting_slot);

      /* Oct 2022: when a meeting contains a slot which is not part of the event date we ran into an error */
      if (dx < 0) {
        console.error("We found a meeting with a timeslot which is not part of the event:", meeting_slot);
        continue;
      }

      slots[dx].is_booked = true;
      slots[dx].meeting = m;
    }
  }

  /* Add a reference too the room for each slot. */
  for (var i = 0; i < slots.length; ++i) {
    slots[i].room = room;
  }
  
  return {
    room: room,
    slots: slots,
  }
}

/*

  This is the final function which is used in the Room Schedule
  Screen. it will collect all the rooms for the given
  `eventDateId` and it will generate an array with slots in the
  range of the `eventDateId`. For example from 00:00 until
  23:30. And for each slot that has a meeting, we set the meeting
  object and set `is_booked` to `true`

*/
function lcGetAllRoomsWithAllSlotsForEventDateId(data, eventDateId) {

  let result = [];
  if (!data || !data.meetingRooms) {
    return result;
  }

  for (var i = 0; i < data.meetingRooms.length; ++i) {
    
    var room = data.meetingRooms[i];
    var room_with_slots = lcGetRoomWithAllSlotsForEventDateIdAndRoomId(data, eventDateId, room.id);
    if (!room_with_slots) {
      console.error("Failed to get all the slots for the given event date and room.", room);
      return false;
    }

    result.push(room_with_slots);
  }

  return result;
}

// function lcGetMeetingStartTimeSlot(meeting) {
//   return meeting.timeSlots[0];
// }

// function lcGetMeetingEndTimeSlot(meeting) {

//   /* When there is only one time slot we return the only one. */
//   if (meeting.timeSlots.length == 1) {
//     return meeting.timeSlots[0];
//   }
  
//   return meeting.timeSlots[meeting.timeSlots.length - 1];
// }

// function lcGetSlotsStartIndexForMeeting(slots, meeting) {

//   let start_slot = lcGetMeetingStartTimeSlot(meeting);
//   if (!start_slot) {
//     console.log("Cannot get start time slot index for meeting; failed to get the start time slot.");
//     return false;
//   }

//   return slots.findIndex((slot) => slot.start == start_slot);
// }

// function lcGetSlotsEndIndexForMeeting(slots, meeting) {
  
//   let end_slot = lcGetMeetingEndTimeSlot(meeting);
//   if (!end_slot) {
//     console.log("Cannot get end time slot index for meeting; failed to get the start time slot.");
//     return false;
//   }

//   if (end_slot == slots[0].start) {
//     return 0;
//   }
//   //console.log("## END TIMESLOT: " +end_slot +" SLOTS: " +JSON.stringify(meeting.timeSlots));
//   return slots.findIndex((slot) => slot.end == end_slot);
//   //return slots.findIndex((slot) => slot.start == end_slot);
// }

/* 
  Gets all the time slots that fall in the range for the given
  eventDateId. This generates a default array with time slots,
  where each time slot `is_booked` is set to false. `data` is
  returned by the API as described in the comments of
  `lcExtractMeetingsForEventDateIdAndRoomId()`
*/
function lcGetAllTimeSlotsForEventDateId(data, eventDateId) {

  let date_info = lcGetEventDateById(data, eventDateId);
  if (!date_info) {
    console.error("Cannot get all time slots for the given event date id; event date was not found.");
    return false;
  }

  let slots = lcGetTimeSlotsForRangeExcludingEnd(
    date_info.meetingStartTimeSlot,
    date_info.meetingEndTimeSlot
  );

  return slots;
}

function lcGetEventDateById(data, eventDateId) {

  if (!data || !data.eventDates) {
    console.error("No `eventDates` found in the given `data` object. The `data` object should be the result of the API");
    return false;
  }

  for (var i = 0; i < data.eventDates.length; ++i) {
    let ev = data.eventDates[i];
    if (ev.id == eventDateId) {
      return ev;
    }
  }

  console.error("No `eventDate` found for the given id: ", eventDateId);
  return false;
}

/* 

  This function extracts a room for the given id. Data should be
  as described for the
  `lcExtractMeetingsForEventDateIdAndRoomId()` function.

  The object we return looks like:
  
  ```js
  {
    id: 0,
    title: "Room 1",
  }
  ```
*/
function lcGetRoomById(data, roomId) {

  if (!data || !data.meetingRooms) {
    return false;
  }

  for (var i = 0; i < data.meetingRooms.length; ++i) {
    let room = data.meetingRooms[i];
    if (room.id == roomId) {
      return room;
    }
  }

  console.error("No meeting room found with the id: ", roomId);
  return false;
}

/* 
  This function will extract the dates from the `data.eventDates`
  array in such a way that they can be shown in the filter of the
  Meeting Room Schedule screen. See
  `lcExtractMeetingsForEventDateIdAndRoomId()` for what we expect
  as the `data` that is passed into this fuction.

  We return:

 */
function lcGetFormattedEventDates(data) {

  var result = [];
  if (!data || !data.eventDates) {
    return result;
  }

  for (var i = 0; i < data.eventDates.length; ++i) {
    var ed = data.eventDates[i];

    result.push({
      ...ed,
      index: i, /* We add an index as that can be used to set the selected date index, see `SdRoomSchduleScreen`. */
      title: moment(ed.date).format("MMMM D"),
    });
  }

  return result;
}

/* --------------------------------------------------------------- */

/*
  This function will extract the `users` from the result of the
  `/servicedesk/registration/data-match`  API end-point. This
  function will also automatically set the topic objects,
  departments, etc. The resulting array can be used directly to
  render something. This is currently used on the
  `SdRepresentativeMatchScreen`.
*/
function lcGetAllRepresentativeMatchUsers(data) {

  if (!data || !data.users) {
    return false;
  }

  let result = [];
  for (var i = 0; i < data.users.length; ++i) {
    
    let user = data.users[i];

    result.push({
      ...user,
      hidden: false, /* Used to toggle visibility in the list. */
      topics: lcGetRepresentativeMatchTopicsForUser(data, user),
      department: lcGetRepresentativeMatchDepartmentForUser(data, user),
    });
  }

  /* See https://stackoverflow.com/a/47920897 */
  result.sort((a, b) => {
    return ((+b.available) - (+a.available) || a.lastName.localeCompare(b.lastName));
  });

  return result;
}

/* 
  This function is used to get the topic objects that are needed
  to render the representatives on the
  `ScRepresentativeMatchScreen`.
*/
function lcGetRepresentativeMatchTopicsForUser(data, user) {

  if (!data || !data.topics || !user || !user.topics) {
    return [];
  }

  let result = [];
  for (var i = 0; i < user.topics.length; ++i) {
    
    let topic = lcGetRepresentativeMatchTopicByTopicId(data, user.topics[i]);
    if (!topic) {
      console.error("Topic with ID not found: ", user.topics[i]);
      continue;
    }

    result.push(topic);
  }

  return result;
}

function lcGetRepresentativeMatchTopicByTopicId(data, topicId) {

  if (!data || !data.topics) {
    return false;
  }

  let topic = data.topics.find((top) => top.id == topicId);
  
  return topic;
}

function lcGetRepresentativeMatchDepartmentForUser(data, user) {

  if (!data || !data.departments || !user) {
    return null;
  }

  let department = data.departments.find((dep) => dep.id == user.department);
  return department;
}

/* 
  This function extracts the locations from the `data`, which is 
  the result of the `/servicedesk/registration/data-match` API
  endpiont. We add some fields to the result which is used by the
  `SdRepresentativeMatchScreen`. 
*/
function lcGetRepresentativeMatchLocations(data) {

  let locs = [];
  if (!data || !data.locations) {
    return locs;
  }

  for (var i = 0; i < data.locations.length; ++i) {
    locs.push({
      index: i, /* The index can be used to filter on a selected index. */
      ...data.locations[i],
      active: false, /* By default the location is unchecked in the screen. */
    });
  }

  return locs;
}

/* --------------------------------------------------------------- */

/*
  This function returns an array with countries as used on the
  registration form. We except the data from the
  `/api/v1/servicedesk/registration/data` endpoint.
*/
function lcGetRegistrationFormCountries(data) {

  let countries = [];
  if (!data || !data.countries) {
    return countries;
  }
  
  countries = data.countries.map((c, dx) => {
    c.index = dx;
    c.title = c.name;
    return c;
  });
  
  return countries;
};

/* 
  Helper for the registration form; gets an array 
  with the industries of interests; prepared to be used
  on the form.
*/
function lcGetRegistrationFormIndustries(data) {

  let inds = [];
  if (!data || !data.industries) {
    return inds;
  }

  inds = data.industries.map((ind, dx) => {
    ind.index = dx;
    ind.checked = false;
    return ind;
  });

  return inds;
}

/*
  Helper to create a flat array with the different levels of
  products of interest; the backend dev doesn't want to create a
  proper table structure for this hierarchical data so I have to
  create it here.
*/
function lcGetRegistrationFormProductsOfInterest(data) {

  let prods = [];
  if (!data) {
    return prods;
  }

  var products0 = data.products || [];
  var products1 = data.productsLevel2 || [];
  var products2 = data.productsLevel3 || [];

  var level0 = products0.map((el) => {
    return {
      ...el,
      id: '0.' +el.id,
      parentid: null,
      checked: false,
    }
  });

  var level1 = products1.map((el) => {
    return {
      ...el,
      id: '1.' +el.id,
      parentid: '0.' +el.parentId,
      checked: false,
    }
  });

  var level2 = products2.map((el) => {
    return {
      ...el,
      id: '2.' +el.id,
      parentid: '1.' +el.parentId,
      checked: false,
    }
  });

  prods = level0.concat(level1).concat(level2);

  for (var i = 0; i < prods.length; ++i) {
    prods[i].index = i;
  }
  
  return prods;
}

/* --------------------------------------------------------------- */

/* 
  Extract the rooms arary from the data returned by the
  `/api/v1/servicedesk/meeting/detail` endpoint. This is used on
  the meeting detail form in the Service Desk app.

  REFERENCES:

    [0]: https://gist.github.com/roxlu/14f826690b7a0dc358deb9f8ca0e96bb

*/
function lcGetMeetingFormRooms(data) {

  let rooms = [];
  if (!data || !data.meetingRooms) {
    return rooms;
  }

  rooms = data.meetingRooms.map((room, dx) => {
    return {
      id: room.id,
      title: room.title,
      room: room,
      index: dx,
    }
  });

  return rooms;
}

/*
  This function will create the array that is used on the form
  for the time-from and time-until selects. We expect the result
  of the `/api/v1/servicedesk/meeting/detail` endpoint.

  REFERENCES:

    [0]: https://gist.github.com/roxlu/14f826690b7a0dc358deb9f8ca0e96bb

*/
function lcGetMeetingFormTimeSlots(data) {

  let result = {
    slots_from: [],
    slots_until: [],
  };
  
  if (!data || !data.eventDate) {
    return result;
  }

  result.slots_from = lcGetTimeSlotsForRangeExcludingEnd(
    data.eventDate.meetingStartTimeSlot,
    data.eventDate.meetingEndTimeSlot
  );

  result.slots_until = lcGetTimeSlotsForRangeIncludingEnd(
    data.eventDate.meetingStartTimeSlot,
    data.eventDate.meetingEndTimeSlot
  );

  const createSlotInfo = (slot, dx) => {
    return {
      title: slot.start_title,
      id: slot.start,
      index: dx,
      slot: slot
    };
  };

  result.slots_from = result.slots_from.map(createSlotInfo);
  result.slots_until = result.slots_until.map(createSlotInfo);

  return result;
};

/*
  Get an array with users that can be used in a select on the
  meeting details form.
  
  REFERENCES:

    [0]: https://gist.github.com/roxlu/14f826690b7a0dc358deb9f8ca0e96bb

*/
function lcGetMeetingFormUsers(data) {

  let users = [];
  if (!data || !data.users) {
    return users;
  }

  users = data.users.map((user) => {
    return {
      id: user.id,
      title: `${user.firstName} ${user.lastName}`,
      user: user,
    };
  });

  return users;
}

/*
  This function will return the full name of the user who booked
  the meeting. This can be either the selected user or a
  free-input user name. We expect the given `data` to contain:

  ```js
    meeting: { 
      bookedByUser: { 
        firstName: ..,
        lastname: ..., 
      },
      bookedByName: ...
    }

  ```

  @return  `Firstname Lastname` 

*/
function lcGetMeetingBookedByName(data) {

  if (!data || !data.meeting) {
    return '';
  }

  let by_name = data.meeting.bookedByName.trim();
  if (0 != by_name.length) {
    return by_name;
  }

  let by_user = data.meeting.bookedByUser;
  return `${by_user.firstName} ${by_user.lastName}`;
}

/* --------------------------------------------------------------- */

/* 
  Used by the Meeting Room app to get a list with event dates
  that can be used by a `LcFilter` component. We expect data 
  to contain the result of the` /meetingroom/meeting/overview`
  api end point.
*/
function mrGetEventDates(data) {

  let result = [];
  if (!data || false == Array.isArray(data.eventDates)) {
    return result;
  }

  result = data.eventDates.map((date, dx) => {
    return {
      id: date.id,
      index: dx,
      title: moment(date.date).format("MMMM D"),
      date: date,
    };
  });

  return result;
}

/*

  This function will return an array with all the slots that are
  availalle for the given `eventDateId`. When an slot has been
  booked we will also set the `is_booked` flag for all the slots
  that are applicable to true. Each booked slot also gets a
  member `meeting` which refers to the meeting that occupies the
  slot. We expect `data` to contain the result of the
  `/meetingroom/meeting/overview` end point.  

*/
function mrGetAllSlotsForEventDateIdAndRoom(data, eventDateId, roomId) {

  let result = [];
  if (!data || !eventDateId || !data.eventDates) {
    return result;
  }

  /* First, get all slots for the given event date. */
  let event_date = data.eventDates.find((d) => d.id == eventDateId);
  if (false == !!event_date) {
    console.error("No event date info found for date: ", eventDateId);
    return result;
  }

  /* Generate -all- slots for this date. */
  let slots = lcGetTimeSlotsForRangeExcludingEnd(event_date.meetingStartTimeSlot, event_date.meetingEndTimeSlot);
  if (!slots) {
    console.error("Failed to generate the -all- slots array.");
    return result;
  }

  /* Map the meetings for this room and set `is_booked` to true for the booked slots. */
  if (data.meetings) {
    
    for (let i = 0; i < data.meetings.length; ++i) {

      let m = data.meetings[i];
      if (m.meetingRoom != roomId) {
        continue;
      }

      if (m.eventDate != eventDateId) {
        continue;
      }

      for (var j = 0; j < m.timeSlots.length; ++j) {

        let meeting_slot_code = m.timeSlots[j];
        let slot = slots.find((slot) => slot.start == meeting_slot_code);

        if (false == !!slot) {
          console.error("Failed to find the slot from the meeting in our all-slots arary. Should not happen.");
          return result;
        }

        /* Set `is_booked` flag and set a reference to the meeting. */
        slot.is_booked = true;
        slot.meeting = m;
      }
    }
  }

  for (let i = 0; i < slots.length; ++i) {
    slots[i].index = i;
  }

  return slots;
}

/*
  This function creates an array with rooms which is used by the
  room filter on the Meeting Roomn App. We expect the `data` to
  contain the result of the `/meetingroom/meeting/overview` end
  point.
*/
function mrGetRooms(data) {

  let result = [];
  if (!data || !data.meetingRooms) {
    return result;
  }

  result = data.meetingRooms.map((room, dx) => {
    return {
      id: room.id,
      index: dx,
      title: room.title,
    };
  });
  
  return result;
};

/*
  This function will collect all the meeting data which is
  required to display the meeting for the Meeting Room app. We
  expect the `data` to contain the result of the
  `/meetingroom/meeting/overview` end point.

  @param data (object):  We expect `data` to contain the result of the `/meetingroom/meeting/overview` end point.  
  @param slots (array):  Parsed results, by `mrGetAllSlotsForEventDateIdAndRoom`
  @param slot (object):  Selected slot for which you want info
  @param room (object):  Selected room.

*/
function mrGetMeetingDetailsForSlotWithRoom(data, slots, slot, room) {

  if (!data || !slot || !room || !slots) {
    return false;
  }

  /* Get the meeting duration string and meeting date. */
  let duration = null;
  if (slot.meeting && slot.meeting.timeSlots) {
    duration = lcGetTimeSlotsDurationStringFromSlotCodesArray(slot.meeting.timeSlots);
  }

  /* Get the date when we have a meeting */
  let date = null;
  if (slot.meeting && data.eventDates) {
    let event_date = data.eventDates.find((d) => d.id == slot.meeting.eventDate);
    date = moment(event_date.date).format("MMMM D");
  }

  /* When the slot hasn't been booked we show the "free" duration; up until the next meeting starting from the previous meeting. */
  if (!slot.meeting) {
    duration = lcGetDurationStringFromAvailableRangeForSlotIndex(slots, slot.index);
  }

  /* Get the room info for this slot. */
  let info = {
    room: room,
    slot: slot,
    meeting: slot.meeting,
    duration: duration,
    date: date,
  };

  return info;
};

/*
  This function is used to show the next/upcoming meeting
  relative to the current time. This function will return 
  an object with:

  @param slots (array):  Parsed results, by `mrGetAllSlotsForEventDateIdAndRoom`

  @return (object | boolean)

    - slot (object):  
    - duration (string):   The duration of the next slot
    - meeting (object):    The next meeting.

  ... or ...
  
    We return FALSE when we didn't find a next meeting.
  
*/
function mrGetUpcomingMeetingDetails(data, slots, room, eventDateId) {

  /* Yeah... we really want to make sure the data we get is valid. */
  if (!data
      || !slots
      || false == Array.isArray(slots)
      || slots.length == 0
      || !room
      || !eventDateId
     )
  {
    return false;
  }

  let curr_slot_code = lcGetCurrentTimeSlotCode();
  
  //console.log("@todo harcoded the curr slot code!!!");
  //curr_slot_code = "1000";
  
  let curr_slot = slots.find((slot) => slot.start == curr_slot_code);

  /* 
    The code from where we try to find the next meeting. When 
    there is a meeting in progress we try to find the next 
    meeting.
  */
  let last_code = curr_slot_code;

  /* 
    When there are meetings, it may happen that the current 
    time slot (`curr_slot_code`, and `last_code`)  is part
    of the meeting which is currently in progress. That means
    that the "Next Meeting" is the one after the current one. 
    
    Below, we first find the current meeting and get the 
    last time code of this meeting. After the `if(data.meetings)`
    the `last_code` is either the current time slot
    OR the last time slot of the current meeting.

    We use this `last_code` as a starting point when searching
    for the next time slot which has been booked. When we find 
    a next slot, we grab the meeting and use that to create the
    details that are needed to show information about the
    next meeting.
   */
  if (data.meetings) {

    let curr_meeting = null;
    
    for (let i = 0; i < data.meetings.length; ++i) {
      
      let m = data.meetings[i];

      /* Make sure we're checking a meeting for the same room */
      if (m.meetingRoom != room.id) {
        continue;
      }

      /* ... and same date. */
      if (m.eventDate != eventDateId) {
        continue;
      }
      
      let contains_curr_slot_code = m.timeSlots.find((meetingSlotCode) => meetingSlotCode == curr_slot_code);
      if (!contains_curr_slot_code) {
        continue;
      }
      
      curr_meeting = m;
      break;
    }

    /* There is a meeting in progress, so we have to find the next one. */
    if (null != curr_meeting) {
      let sorted = curr_meeting.timeSlots;
      last_code = _.last(sorted);
    }
  }

  /* Find the next booked slot */
  let last_slot = slots.find((slot) => slot.start == last_code);
  if (!last_slot) {
    /* We may arrive here when the `last_code` is -after- the last time slot of the event. */
    last_slot = _.last(slots);
  }
  
  let next_slot = null;
  for (var i = (last_slot.index + 1); i < slots.length; ++i) {
    if (true == slots[i].is_booked) {
      next_slot = slots[i];
      break;
    }
  }

  /* When we didn't find a next meeting, we return false. */
  if (null == next_slot) {
    return false;
  }

  /* When found, we generate the duration string. */
  let next_duration = null;
  let next_meeting = null;

  if (null != next_slot) {
    next_duration = lcGetTimeSlotsDurationStringFromSlotCodesArray(next_slot.meeting.timeSlots);
    next_meeting = next_slot.meeting;
  }

  return {
    duration: next_duration,
    meeting: next_meeting,
    slot: next_slot,
  };
};

/* --------------------------------------------------------------- */

/*

  This function is used by the meeting room app to select the 
  best date that we should use to display. First we check 
  if the we can find the date of today, then we check if 
  the closest matching ones.

  We expect an array of dates like:

    ['20211102', '20211103', '20211104', '20211106', '20211116', '20211117', '20211118']

  When you have an array with a different structure you can use 
  `map()` to easily create another one; e.g.:

     let tmp = dates.map((d) => d.date.date);

*/
function lcGetBestMatchingDateIndex(dates) {

  if (!dates) {
    return null;
  }

  /* `tmp` will hold something like `20211106`; you can change it to test. */
  let tmp = moment().format("YYYYMMDD");
  let today = moment(tmp);
  let today_ts = today.unix();
  let prev_dx = -1;
  let next_dx = -1;

  /* Create moment objects for given dates and sort them. */
  let fixed = dates.map((d) => moment(d));
  fixed.sort((a, b) => a.isAfter(b) ? 1 : -1);

  for (var i = 0; i < fixed.length; ++i) {

    let date = fixed[i];
    let date_ts = date.unix();
    
    if (date_ts == today_ts) {
      return i;
    }

    if (date_ts < today_ts) {
      prev_dx = i;
      continue;
    }

    if (next_dx == -1) {
      next_dx = i;
      continue;
    }
  }

  if (next_dx >= 0) {
    return next_dx;
  }
  
  if (next_dx < 0 && prev_dx >= 0) {
    return prev_dx;
  }

  return null;
};

/* --------------------------------------------------------------- */

/*
  This function is used to restructure the data we receive from
  the `api/v1/meetingroom/app/data` end point so it can be easily
  used on the front end to select the meeting room for which the
  app should work.
*/
function mrGetAdminEventData(appData) {

  let result = [];
  if (!appData || !appData.events || !appData.meetingRooms) {
    return result;
  }

  for (let i = 0; i < appData.events.length; ++i) {
    
    let event = appData.events[i];
    let rooms = appData.meetingRooms.filter((room) => room.event == event.id);

    result.push({
      event: event,
      rooms: rooms
    });
  }

  return result;
};

/* --------------------------------------------------------------- */

function jss(title, obj, depth) {
  depth = depth || 2;
  console.log(title.toUpperCase(), JSON.stringify(obj, null, depth));
}

/* --------------------------------------------------------------- */

export {

  /* Shared */
  lcGetTimeSlotsForRange,
  lcGetTimeSlotsForRangeExcludingEnd,
  lcGetTimeSlotsForRangeIncludingEnd,
  lcGetTimeSlotCodesForRangeExcludingEnd, 
  lcGetTimeSlotCodesForRange,             /* Only returns the codes for the range. */
  lcGetTimeSlotMinMaxFromSlotCodesArray,
  lcGetTimeSlotsDurationStringFromSlotCodesArray,
  lcGetTimeSlotAfterSlot,
  lcGetBestMatchingDateIndex, /* Currently created for the Meeting Room app to select the date index that we should use ... */

  /* Used by the Service Desk App */
  lcGetAllRoomsWithAllSlotsForEventDateId,
  lcGetFormattedEventDates,
  lcGetAllRepresentativeMatchUsers,
  lcGetRepresentativeMatchLocations,
  lcGetRegistrationFormCountries,
  lcGetRegistrationFormIndustries,
  lcGetRegistrationFormProductsOfInterest,
  lcGetMeetingFormRooms,
  lcGetMeetingFormTimeSlots,
  lcGetMeetingFormUsers,
  lcGetMeetingBookedByName,
  lcGetCurrentTimeSlotCode,

  /* Used by the Meeting Room app (mostly) */
  mrGetEventDates,
  mrGetAllSlotsForEventDateIdAndRoom,
  mrGetRooms,
  mrGetMeetingDetailsForSlotWithRoom,
  mrGetUpcomingMeetingDetails,
  mrGetAdminEventData,

  /* Debug Helpers */
  jss, 
}


/* --------------------------------------------------------------- */
 
