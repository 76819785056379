/*
   ---------------------------------------------------------------

   SCAN2LEAD BADGE SCAN
   =====================

   GENERAL INFO:

     This `SdBadgeScan` takes care of reading
     QR/DataMatrix/AzTec/etc.  We create a WebSocket client and
     connect to the `poly-websocket-proxy`. We use the
     `poly-newland-websocket-client` which connects to the
     `poly-websocket-proxy`. Whenever a code is read, using a
     Newland scanner, it is send to the proxy and then forwarded
     to this `SdBadgeScan`.

     Whenever we receive a QR code we call `onCodeFound(code)`. The
     handle is currently in `SdRegisterVisitorScreen`. We exchange
     the code for the visitor information which is then automatically
     filled in, into the register visitor form.
   
 */
/* ------------------------------------------------------- */

const SdBadgeScan = {

  ws: null,  /* Our websocket connection to the proxy; we receive QR codes from this connections. */
  
  init: function() {

    var me = this;
    let url = "ws://127.0.0.1:5566";
    
    this.ws = new WebSocket(url);

    this.ws.onopen = function() {
      console.log("WebSocket connection opened.");
    };

    this.ws.onmessage = function(ev) {
      console.log("WebSocket message:", ev.data);

      if (!ev?.data) {
        console.log("Received a message but it doesn't have a `data` member.");
        return false;
      }

      try {
        let msg = JSON.parse(ev.data);
        if (msg?.type == "QR_CODE_FOUND") {
          me?.onCodeFound?.(msg?.uid);
        }
      }
      catch (err) {
        console.error("Failed to handle the received message: ", err, ev?.data);
        return false;
      }
    }

    this.ws.onerror = function(err) {
      console.log("WebSocket error: ", err);
    };

    this.ws.onclose = function() {
      console.log("WebSocket close");
    };
  },

  shutdown: function() {

    if (this.ws) {
      console.log("CLOSING!");
      this.ws.close();
      delete this.ws;
      this.ws = null;
    }
  },
};

/* ------------------------------------------------------- */

export {
  SdBadgeScan
};

/* ------------------------------------------------------- */
