/* --------------------------------------------------------------- */

import CircularProgress from '@mui/material/CircularProgress';

/* --------------------------------------------------------------- */

function LcCircularProgress(props) {

  return (
    <>
    <CircularProgress className="lc-circular-progress" />
    </>
  )

};

/* --------------------------------------------------------------- */

export {
  LcCircularProgress,
};

/* --------------------------------------------------------------- */

