/*

  STACK
  ======

*/
/* --------------------------------------------------------------- */

import Stack from '@mui/material/Stack';

/* --------------------------------------------------------------- */

function LcStack(props) {

  return (
    
    <Stack
      className={props.className}
      spacing={props.spacing}
      direction={props.direction}
      alignItems={props.alignItems}
    >
      {props.children}
    </Stack>
  );
};

/* --------------------------------------------------------------- */

export default LcStack;

/* --------------------------------------------------------------- */
