/* --------------------------------------------------------------- */

import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { ReactComponent as BadgeScanIcon } from 'img/badge-scan-icon.svg';
import { ReactComponent as RegisterVisitorIcon } from 'img/register-visitor-icon.svg';
import { ReactComponent as ManageMeetingRoomsIcon } from 'img/manage-meeting-rooms-icon.svg';
import { ReactComponent as HomeIcon } from 'img/oq-home-icon.svg';
import { ReactComponent as ArrowRight } from 'img/arrow-right.svg';
import { LcTextButton } from 'lib/ui/LcButton';
import { LcClock } from 'lib/ui/LcClock';
import { useLcContext } from 'lib/core/LcContext';
import { SdSidePanelPage, SdSidePanelBar, SdSidePanelContent } from 'ui/SdSidePanel';
import LcConfig from 'core/LcConfig';

/* --------------------------------------------------------------- */

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Button from '@mui/material/Button';

/* --------------------------------------------------------------- */

function SdHomeScreenButton(props) {

  const onPressed = (ev) => {
    props.onClick(props);
  };

  return (
    <>
    <Link to={props.link} className="lc-home-screen-button">
      {props.icon}
      <strong>{props.textBold}&nbsp; </strong>
      {props.textNormal}
      <ArrowRight className="lc-home-screen-button-arrow" />
    </Link>
    </>
  )
};

/* --------------------------------------------------------------- */

function SdHomeScreen() {

  const history = useHistory();
  const ctx = useLcContext();

  /* When the user clicked the `home` button we will unset any stored visitor data. */
  useEffect(() => {
    ctx.dispatch(ctx.actions.unsetVisitorData());
  }, []);
  
  const onRepresentativeScheduleClicked = (ev) => {
    ev.preventDefault();
    history.push("/schedule");
  };

  return (
    <SdSidePanelPage className="lc-home-screen-page">
      <SdSidePanelBar className="lc-home-screen-sidebar" />
      <SdSidePanelContent >
        
        <div className="lc-home-screen-buttons">
          <h1><strong>{LcConfig.event_title}</strong></h1>

          {LcConfig.use_badge_scan &&
           <SdHomeScreenButton
             icon={<BadgeScanIcon />}
             link="/badge-scan"
             textBold="Scan"
             textNormal="visitor badge"
           />
          }
          
          <SdHomeScreenButton
            icon={<RegisterVisitorIcon />}
            link="/register-visitor"
            textBold="Manually register "
            textNormal="visitor"
          />
          
          <SdHomeScreenButton
            icon={<ManageMeetingRoomsIcon />}
            link="/rooms"
            textBold="Manage "
            textNormal="meeting rooms"
          />

          <LcTextButton
            className="lc-home-representative-button"
            title="Representative schedule"
            onClick={onRepresentativeScheduleClicked}
          />
          
      </div>

      </SdSidePanelContent>
    </SdSidePanelPage>
  );
};

/* --------------------------------------------------------------- */

export default SdHomeScreen;

/* --------------------------------------------------------------- */
