/* --------------------------------------------------------------- */

import { useContext, createContext, useReducer } from 'react';
import { lcReducer, LcInitialState } from 'lib/core/LcReducer';
import { LcActions } from 'lib/core/LcActions'; 

/* --------------------------------------------------------------- */

const LcContext = createContext();

/* --------------------------------------------------------------- */

function LcContextProvider({children}) {

  const [state, dispatch] = useReducer(lcReducer, LcInitialState);

  const ctx = {
    state,
    dispatch,
    actions: LcActions,
  };
  
  return (
    <LcContext.Provider value={ctx}>{children}</LcContext.Provider>
  );
};

/* --------------------------------------------------------------- */

const useLcContext = () => useContext(LcContext);

/* --------------------------------------------------------------- */

export {
  useLcContext,
  LcContextProvider,
}

/* --------------------------------------------------------------- */
