/* --------------------------------------------------------------- */

import { useState, useEffect, useRef } from 'react';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import { useSpring, animated, config as MomentConfig} from 'react-spring';
import LcCheckbox from 'lib/ui/LcCheckbox';
import LcPageHeader from 'lib/ui/LcPageHeader';
import LcStack from 'lib/ui/LcStack';
import LcFilter from 'lib/ui/LcFilter';
import { LcClock } from 'lib/ui/LcClock';
import { LcDarkTextInput } from 'lib/ui/LcTextInput';
import { LcDarkSelect } from 'lib/ui/LcSelect';
import { LcLargeTitle, LcColumnTitle } from 'lib/ui/LcTitles';
import { LcHomeButton, LcEditButton, LcDeleteButton, LcCloseIconButton, LcButton, LcNextButton, LcPreviousButton, LcDarkButton, LcDarkTextButton } from 'lib/ui/LcButton';
import { LcPageLogo } from 'lib/ui/LcLogo';
import { LcProgressBar } from 'lib/ui/LcProgress'
import { ReactComponent as ClockIcon } from 'lib/img/clock-icon.svg';
import { ReactComponent as OrganizerIcon } from 'lib/img/organizer-icon.svg';
import { SdSidePanelPage, SdSidePanelBar, SdSidePanelContent } from 'ui/SdSidePanel';
import LcRoomLogo from 'img/manage-meeting-rooms-oq-logo.png';

import {
  useLcFetchMeetingOverviewData,
  useLcFetchMeetingDetails,
  useLcSaveMeeting,
  useLcDeleteMeeting,
} from 'lib/core/LcApi';

import {
  lcGetTimeSlotsForRange,
  lcGetTimeSlotCodesForRange,
  lcGetTimeSlotsDurationStringFromSlotCodesArray,
  lcGetTimeSlotMinMaxFromSlotCodesArray,
  lcGetTimeSlotAfterSlot,
  lcGetFormattedEventDates,
  lcGetAllRoomsWithAllSlotsForEventDateId,
  lcGetMeetingFormRooms,
  lcGetMeetingFormTimeSlots,
  lcGetMeetingFormUsers,
  lcGetMeetingBookedByName,
  lcGetBestMatchingDateIndex,
} from 'lib/core/LcUtils';

/* --------------------------------------------------------------- */

function js(obj, depth) {
  return JSON.stringify(obj, null, depth || 0);
};

/* --------------------------------------------------------------- */

function SdRoom(props) {
  return (
    <div className="lc-rooms-room-row">
      <div className="lc-rooms-room-title">
        <strong>{props.info.room.title}</strong>
      </div>
    </div>
  );
};

/* --------------------------------------------------------------- */

function SdSlot(props) {

  const onClick = (ev) => {

    if (props.onSlotClicked) {
      props.onSlotClicked(props);
    }
  };
  
  let classes = ['lc-rooms-slot'];
  if (true == props.is_booked) {
    classes.push('booked');
  }
  
  return (
    <div className="lc-rooms-slot-container" onClick={onClick} >
      <div className={classes.join(" ")}>
        {props.start_title}
      </div>
    </div>
  );
};

function SdSlots(props) {

  return (
    <div className="lc-rooms-slots-row">
      { props.slots.map((slot, dx) => <SdSlot key={dx} {...slot} onSlotClicked={props.onSlotClicked} />) }
    </div>
  )
};

/* --------------------------------------------------------------- */

/*

  Render a form to edit or save a meeting in a room. 

  @param props:

  - rooms (array):      Array with all the rooms and all slot information.
  - eventDate (object): Object with info about the selected event data.

*/
function SdMeetingForm(props) {

  const [room_id, setRoomId] = useState(props.slot.room.id);
  const [data, error, is_fetching, fetchMeetingDetails] = useLcFetchMeetingDetails();

  /* --------------------------------------------------------------- */
  /* Formik related functions                                        */
  /* --------------------------------------------------------------- */
  /* 
    When we submit the form, we generate a clean meeting object
    and pass that into the save click handler.
  */
  const onFormikSubmit = (values) => {

    let meeting = {
      ...values,
      slots: lcGetTimeSlotCodesForRange(values.time_from, values.time_until),
    };

    props.onSaveClicked(meeting);
  };

  /*
    I probably could have done this a bit cleaner/shorter, but
    for simplicity I've created two flows; first when we're
    creating a new form (props.data is not set), we fill some
    defaults from the given slot; otherwise we use data we load
    via the API.
   */
  let initial_values = null;
  let slot_range = null; 
  if (!props.data) {

    /* Initial values for update. */
    initial_values = {
      meeting_id: null,
      event_date_id: props.eventDate.id,
      room_id: room_id,
      time_from: props.slot.start,
      time_until: props.slot.end,
      booked_by_user: null,
      booked_by_name: null,
      description: null,
    };
  }
  else {

    slot_range = lcGetTimeSlotMinMaxFromSlotCodesArray(props.data?.meeting?.timeSlots);
    
    /* Initial values for insert. */
    initial_values = {
      meeting_id: props.data?.meeting?.id,
      event_date_id: props.data?.eventDate?.id,
      room_id: props.data?.meeting?.meetingRoom?.id,
      time_from: slot_range.min_slot.start,
      time_until: slot_range.max_slot.end,
      booked_by_user: props.data?.meeting?.bookedByUser?.id,
      booked_by_name: props.data?.meeting?.bookedByName,
      description: props.data?.meeting?.description,
    };
  }

  /* Did the use either selected a user or entered a name? */
  function validateBookedBy(item) {

    if (!this.parent) {
      return false;
    }

    if (this.parent.booked_by_user
        || this.parent.booked_by_name)
    {
      return true;
    }

    return false;
  };
  
  const ValidationSchema = Yup.object().shape({
    time_from: Yup.string().nullable().required(),
    time_until: Yup.string().nullable().required(),
    booked_by_user: Yup.string().nullable().test('required', '...', validateBookedBy),
    booked_by_name: Yup.string().nullable().test('required', '...', validateBookedBy),
    description: Yup.string().required(),
  });
  
  const formik = useFormik({
    initialValues: initial_values,
    onSubmit: onFormikSubmit,
    validationSchema: ValidationSchema,
  });

  /* --------------------------------------------------------------- */

  /* Get the meeting form data. */
  useEffect(() => {
    fetchMeetingDetails(props.eventDate.id, room_id, props.slot.start);
  }, [room_id]);

  /* Extract the data from the retrieved form data. */
  let rooms = lcGetMeetingFormRooms(data);
  let slots = lcGetMeetingFormTimeSlots(data);
  let users = lcGetMeetingFormUsers(data);
  let info = rooms.find((room) => room.id == room_id);

  /* --------------------------------------------------------------- */
  /* Create the in-use slots which need to be disabled.              */
  /* --------------------------------------------------------------- */
  let in_use_slots = [];
  if (info
      && info.room
      && info.room.timeSlotsInUse
     )
  {

    /* Get the slot codes (sorted) from the selected meeting */
    let meeting_slot_codes = [];
    if (props.data && props.data.meeting) {
      meeting_slot_codes = props.data.meeting.timeSlots.sort();
    }

    /*
      As the admin is allowed to change the time slots of the
      meeting he/she has selected we have to exclude the
      `timeSlotsInUse` from the room for the selected
      meeting. BUT, we only are allowed to exclude them when the
      meeting is for the same room as the selected
      slot. Therefore we check if the user switch room.
    */
    let changed_room = (room_id != props.slot.room.id);
    
    in_use_slots = info.room.timeSlotsInUse.filter((slotCode) => {

      if (true == changed_room) {
        return true;
      }
      
      /* The user can modify all slots from the current meeting. */
      let is_code_from_current_meeting = meeting_slot_codes.find((code) => code == slotCode);
      if (is_code_from_current_meeting) {
        return false;
      }

      return true;
    });
  }

  /* --------------------------------------------------------------- */
  /* Disable the slots which are in use already.                     */
  /* --------------------------------------------------------------- */

  const disableInUseSlots = (allSlots) => {
    in_use_slots.forEach((slotId) => {
      let slot = allSlots.find((slot) => slot.id == slotId);
      if (!slot) {
        return;
      }
      slot.disabled = true;
    });
  };

  disableInUseSlots(slots.slots_from);
  disableInUseSlots(slots.slots_until);

  /* --------------------------------------------------------------- */
  /* Create the slots from the `Till` selectbox                      */
  /* --------------------------------------------------------------- */
  let found_disabled = false;
  let selected_from = slots.slots_from.find((slot) => slot.id == formik.values.time_from);

  let slots_till = slots.slots_until.filter((slot) => {

    /* Ignore slots which are before the selected `from`. */
    if (selected_from
        && slot.index <= selected_from.index)
    {
      return false;
    }

    /* Once a disabled slot was found (e.g. a meeting exists) we skip all others. */
    if (true == found_disabled) {
      return false;
    }

    /* Current slot is disabled. */
    if (slot.disabled) {

      /* This user can still select this slot as this is the `until` time; which is not really part of the meeting. */
      if (false == found_disabled) {
        found_disabled = true;
        slot.disabled = false;
        return true;
      }

      return false;
    }
    
    return true;
  });

  /*
    Edge case 1: 

    Here we handle a situation which may happen when the admin
    clicks a free slot on e.g. room 1, then switches to room 2
    where this slot is disabled. In that case we unselect the
    original/currently selected slot.
  */
  if (selected_from
      && selected_from.disabled
     )
  {
    formik.setFieldValue('time_from', null);
  }

  /* 
    Edge case 2: 
    
    Here we handle the situation where the admin didn't 
    select a `from` time yet. This happens during a normal 
    case, where the admin simply didn't select something yet;
    but this may also happen when there are no selectable 
    `from` slots. This happens when all slots for a day have
    been booked.
  */
  if (!selected_from) {
    slots_till = [{
      title: 'No slots available',
      value: null,
      disabled: true,
    }];
  }

  /* --------------------------------------------------------------- */

  const handleRoomChange = (ev, item) => {
    setRoomId(item.props.id);
    formik.setFieldValue('room_id', item.props.id);
  };

  /* 
    When the admin selects someone from the select with 
    the users, we have to clear out the `booked_by_name` 
    field as it's not allowed to enter two user types. 
  */
  const onBookedByUserChange = (ev) => {
    formik.setFieldValue('booked_by_name', null);
    formik.handleChange(ev); 
  };

  const onBookedByNameChange = (ev) => {
    formik.setFieldValue('booked_by_user', null);
    formik.handleChange(ev);
  };

  /* --------------------------------------------------------------- */
  
  return (
    <div className="lc-meeting-form">
      <form onSubmit={onFormikSubmit}>

        <LcDarkSelect
          className="el-room"
          name="room_id"
          value={formik.values.room_id || ''}
          error={formik.errors.room_id}
          onChange={handleRoomChange}
          onBlur={formik.handleBlur}
          items={rooms}
        />

        <LcStack className="ampm" spacing={2} direction="row">
          
          <div className="el-clock"><ClockIcon /></div>

          <label>From</label>
          <LcDarkSelect
            className="el-from"
            name="time_from"
            value={formik.values.time_from || ''}
            error={!!formik.errors.time_from}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            items={slots.slots_from}
          />
          
          <label> Till</label>
          <LcDarkSelect
            className="el-till"
            name="time_until"
            value={formik.values.time_until || ''}
            error={!!formik.errors.time_until}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            items={slots_till}
          />
          
        </LcStack>

        <LcStack className="booked-by" spacing={2} direction="row">
          <div><OrganizerIcon /></div>

          <label>Booked by</label>
          <LcDarkSelect
            name="booked_by_user"
            value={formik.values.booked_by_user || ''}
            error={formik.errors.booked_by_user && formik.touched.booked_by_user}
            placeholder="Representative"
            onChange={onBookedByUserChange}
            onBlur={formik.handleBlur}
            items={users}
          />
          
          <label>Or</label>
          <LcDarkTextInput
            name="booked_by_name"
            value={formik.values.booked_by_name || ''}
            error={formik.errors.booked_by_name && formik.touched.booked_by_name}
            placeholder="Type your name here"
            onChange={onBookedByNameChange}
            onBlur={formik.handleBlur}
          />
          
        </LcStack>

        <LcDarkTextInput
          name="description"
          multiline={true}
          minRows={3}
          maxLength={50}
          value={formik.values.description || ''}
          error={formik.errors.description && formik.touched.description}
          placeholder="Description"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        <LcStack className="el-save-buttons" spacing={2} direction="row">
          <LcDarkButton
            title="Save"
            onClick={formik.handleSubmit}
          />
          <LcDarkTextButton
            title="Cancel"
            onClick={props.onCancelClicked}
          />
        </LcStack>
      </form>
    </div>
  );
};

/* --------------------------------------------------------------- */

function SdMeetingInfo(props) {

  let duration = '';
  let organizer = '';

  if (props.data) {
    duration = lcGetTimeSlotsDurationStringFromSlotCodesArray(props.data?.meeting?.timeSlots);
    organizer = lcGetMeetingBookedByName(props.data);
  }

  /* 
    Tiny content helper to make sure we only fill the popup with
    content after it has been loaded.
  */
  const getContent = () => {

    if (!props.data) {
      return null;
    }

    return (
      <div className="lc-meeting-info">
        <h3 className="lc-meeting-room-name">{props.data?.meeting?.meetingRoom?.title}</h3>
        <div className="lc-meeting-specs">
          <div className="lc-meeting-duration">
            <div className="lc-meeting-spec-icon"><ClockIcon /></div>
            <div className="lc-meeting-spec-duration">{duration}</div>
          </div>
          <div className="lc-meeting-organizer">
            <div className="lc-meeting-spec-icon"><OrganizerIcon /></div>
            <div className="lc-meeting-spec-bookedby">Booked by: <strong> {organizer}</strong></div>
          </div>
        </div>
        <div className="lc-meeting-description">
          {props.data?.meeting?.description}
        </div>
      </div>
    );
  };
  
  return (
    <>
      <div className="lc-meeting-interaction">
        <LcStack spacing={2} direction="row">
          <LcEditButton onClick={props.onEditClicked}/>
          <LcDeleteButton onClick={props.onDeleteClicked} />
        </LcStack>
      </div>
      { getContent() }
    </>
  );
};

/*
  Renders the information about a speficic meeting that the user
  selected. This will also render the form when the user presses
  the edit button.

  @param props (object)
  - slot (object):            The clicked slot
    - meeting (null, object): The meeting for this slot.
  - rooms (array):            Array with all the available rooms.

*/
function SdMeetingInfoAndForm(props) {
  
  const [show_form, setShowForm] = useState(false);
  const [data, error, is_fetching, fetchMeetingDetails] = useLcFetchMeetingDetails();
  
  useEffect(() => {
    if (props?.slot?.meeting) {
      fetchMeetingDetails(props.eventDate.id, props.slot.room.id, props.slot.start);
    }
  }, []);

  if (is_fetching) {
    return null;
  }

  const onSaveClicked = (meeting) => {
    props.onSaveClicked(meeting);
    props.onCloseClicked();
  };

  const onCloseClicked = (ev) => {
    props.onCloseClicked();
  };

  const onEditClicked = (ev) => {
    setShowForm(true);
  };

  const onCancelClicked = (ev) => {
    props.onCancelClicked();
  };

  const onDeleteClicked = (ev) => {

    ev.preventDefault();
    
    if (!data || !data.meeting || !data.meeting.id) {
      console.error("Delete clicked .. but no data? This is a bug.");
      return;
    }

    props.onCloseClicked();
    props.onDeleteClicked(data.meeting);
  };

  let has_meeting = !!props.slot.meeting;
  let form_data = data;

  if (data && !data.meeting) {
    has_meeting = false;
    form_data = null;
  }

  let css_meeting = ["lc-meeting-container"];
  if (has_meeting) {
    css_meeting.push("booked");
  }
  
  /* Just a tiny helper to return the right content. */
  const getContent = () => {

    /* When the slot has a meeting we return the form itself. */
    if (false == has_meeting
        || true == show_form
       )
    {

      return (
        <SdMeetingForm
          {...props}
          data={form_data}
          onSaveClicked={onSaveClicked}
          onCancelClicked={onCancelClicked}
        />);
    }

    /* Otherwise we show the info about the form. */
    return (
      <SdMeetingInfo
        {...props}
        data={data}
        onEditClicked={onEditClicked}
        onDeleteClicked={onDeleteClicked}
      />
    );
  };
  
  return (
    <div className={css_meeting.join(" ")}>
      <LcCloseIconButton id="lc-meeting-close-icon" onClick={onCloseClicked} />
      <div className="lc-meeting-content">
        { getContent() }
      </div>
    </div>
  );
};

/* --------------------------------------------------------------- */

function SdRoomScheduleScreen() {

  const [data, error, fetchMeetingOverviewData] = useLcFetchMeetingOverviewData();
  const [is_deleting, delete_error, deleteMeeting ] = useLcDeleteMeeting();
  const [is_saving, save_meeting_error, saveMeeting] = useLcSaveMeeting();
  const [date_dx, setSelectedDateFilterIndex] = useState(null);
  const [scroll_pos, setScrollPosition] = useState(0);
  const [scroll_change, setScrollChange] = useState(200); /* How much we move when we press next/previous. */
  const [selected_slot, setSelectedSlot] = useState(null); /* When the user clicks a slot we set it into `selected_slot`. */
  const scroll_ref = useRef(null);
  const scroll_props = useSpring({to: { left: scroll_pos}, config: {...MomentConfig.default, duration: 500}});

  const event_dates = lcGetFormattedEventDates(data);
  let rooms = [];

  /* Fetch initial data. */
  useEffect(() => {
    fetchMeetingOverviewData();
  }, []);
  
  /* Set the default date filter index, closest to today. */
  useEffect(() => {

    if (event_dates
        && event_dates.length > 0
        && null == date_dx 
       )
    {
      /* Use util .. */
      let tmp = event_dates.map((d) => d.date);
      let dx = lcGetBestMatchingDateIndex(tmp);
      setSelectedDateFilterIndex(dx);
    }
  }, [data]);

  /* Calculate the offset we change per page. */
  useEffect(() => {
    
    if (!scroll_ref.current) {
      return;
    }
    
    let perc_per_click = 0.75;
    let slot_width = 155;
    let num_slots_per_page = (scroll_ref.current.offsetWidth / slot_width);
    let to_change = (num_slots_per_page * perc_per_click) * slot_width;

    setScrollChange(to_change);
  });

  /* 
    When an date was selected and we have a valid data array from 
    the server, we get all the data that we need to render the 
    list of rooms and their meetings.
  */
  if (null != date_dx && data) {
    
    if (date_dx >= event_dates.length) {
      console.error("The selected date index is out of range.");
      return null;
    }

    let date = event_dates[date_dx];
    rooms = lcGetAllRoomsWithAllSlotsForEventDateId(data, date.id);
  }

  /* We need to pass the selected date id to the schedule form/info popup as it's needed by the API. */
  let event_date = null;
  if (null != date_dx
      && true == Array.isArray(event_dates)
      && date_dx < event_dates.length)
  {
    event_date = event_dates[date_dx];
  }

  /* Check which next/prev button we can enable. */
  let can_scroll_prev = (scroll_pos < 0) ? true : false;
  let can_scroll_next = false;

  if (scroll_ref && scroll_ref.current) {
    /* 
      Because `scroll_pos` may not reach the maximum possible
      position (bug in react-spring?), we add some range here
      (-100). E.g. `scroll_pos` may end at `-1399.6` when the max
      scroll offset can be `-1400`. So by adding `-100` we get
      `-1499.6` as the scroll position that we test.
    */
    let scroll_max = scroll_ref.current.offsetWidth - scroll_ref.current.scrollWidth; /* e.g. -1400 */
    if ((scroll_pos - 100) > scroll_max) {
      can_scroll_next = true;
    }
  }

  /* --------------------------------------------------------------- */

  const onDayFilterChange = (item) => {

    setSelectedDateFilterIndex(item.index);

    /* 
      Make sure we scroll back the slots (maybe we should scroll
      to the current time). 
    */
    setScrollPosition(0);
  };

  const onSlotClicked = async (slot) => {
    setSelectedSlot(slot);
  };

  const onPreviousClicked = () => {
    setScrollPosition(Math.min(scroll_pos + scroll_change, 0));
  };

  const onNextClicked = () => {
    let max = scroll_ref.current.scrollWidth - scroll_ref.current.offsetWidth; 
    setScrollPosition(Math.max(scroll_pos - scroll_change, -max));
  };

  const onMeetingPopupCloseClicked = () => {
    setSelectedSlot(null);
  };

  const onMeetingPopupDeleteClicked = async (meeting) => {
    await deleteMeeting(meeting.id);
    await fetchMeetingOverviewData();
  };

  const onMeetingPopupSaveClicked = async (meeting) => {
    await saveMeeting(meeting);
    await fetchMeetingOverviewData();
  }

  const onMeetingPopupCancelClicked = () => {
    /* Unset the selected slot; removing the popup. */
    setSelectedSlot(null);
  }

  const getScreenContentLoader = () => {
    return (
      <LcProgressBar />
    )
  };
  
  const getScreenContentSchedule = () => {

    return (
      <>
        <div className="lc-rooms-header">
          <div className="lc-rooms-interaction">
            <LcFilter
              title="Date"
              items={event_dates}
              selectedIndex={date_dx}
              onChange={onDayFilterChange}
              className="lc-rooms-date-filter"
            />
            <LcStack spacing={2} direction="row">
              <LcPreviousButton title="Previous" onClick={onPreviousClicked} disabled={!can_scroll_prev} /> 
              <LcNextButton title="Next" onClick={onNextClicked} disabled={!can_scroll_next} />
            </LcStack>
          </div>
        </div>
        
        <div className="lc-rooms-content">
          <div className="lc-rooms-rooms">
            { rooms.map((info, dx) => <SdRoom key={dx} info={info} />) } 
          </div>
          <div className="lc-rooms-slots">
            <animated.div style={scroll_props} className="lc-rooms-slots-scroller" ref={scroll_ref}>
              { rooms.map((room, dx) => <SdSlots key={dx} slots={room.slots} onSlotClicked={onSlotClicked} />) }
            </animated.div>
          </div>

          { null != selected_slot &&
            <SdMeetingInfoAndForm
              slot={selected_slot}
              rooms={rooms}
              eventDate={event_date}
              onSaveClicked={onMeetingPopupSaveClicked}
              onCloseClicked={onMeetingPopupCloseClicked}
              onDeleteClicked={onMeetingPopupDeleteClicked}
              onCancelClicked={onMeetingPopupCancelClicked}
            />
          }
          
        </div>
      </>
    );
  };

  const getScreenContent = () => {

    if (data) {
      return getScreenContentSchedule();
    }
    
    return getScreenContentLoader();    
  };

  /* When a slot has been selected we add an extra class which grows the content when there is not enough space. */
  let right_classes = ["lc-form-right-panel"];
  if (null != selected_slot) {
    right_classes.push("lc-slot-selected");
  }

  return (
    <SdSidePanelPage className="lc-manage-rooms-page">
      <SdSidePanelBar
        title={`Manage\nMeeting Rooms`}
        showHomeButton={true}
      />
      <SdSidePanelContent>
        <div className={right_classes.join(" ")}>
          {  getScreenContent()  }
        </div>
      </SdSidePanelContent>
    </SdSidePanelPage>
  );
};

/* --------------------------------------------------------------- */

export default SdRoomScheduleScreen;

/* --------------------------------------------------------------- */
 
