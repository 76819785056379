/*

  TITLES USED IN THE LC APP
  =========================

  GENERAL INFO:

    Title used above forms, sections, etc.

*/
/* --------------------------------------------------------------- */

import { Typography } from '@mui/material';

/* --------------------------------------------------------------- */

/*
  Currently used for form headers like "Industries of Interest"
  and in some sections on the right, e.g. 'Representative Match
  Screen" on the right.
*/
function LcLargeTitle(props) {

  return (
    <Typography
      variant="h5"
      sx={{
        whiteSpace: 'pre-line',
        marginBottom: '25px',
        fontSize: '24px',
        fontWeight: 'bold',
      }}
    >
      {props.children}
    </Typography>
  );
};

/* --------------------------------------------------------------- */

/*
  Used for the titles on top of the backgrounds in the left
  panel; e.g. "Representative Match".
*/
function LcColumnTitle(props) {

  return (
    <Typography
      className="lc-column-title"
      variant="h5"
      sx={{
        whiteSpace: 'pre-line',
        color: 'white',
        marginBottom: '30px',
        fontSize: '24px',
        fontWeight: 'bold',
      }}
    >
      {props.children}
    </Typography>
  );
};


/* --------------------------------------------------------------- */

export {
  LcLargeTitle,
  LcColumnTitle,
}

/* --------------------------------------------------------------- */


