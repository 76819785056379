/*

  BUTTON 
  ======

  GENERAL INFO:

    Basic button with styles that are used in the Service Desk
    and Meeting Rooms apps.

  REFERENCES:

    [0]: https://mui.com/components/material-icons/  "Searchable list with icons"
*/
/* --------------------------------------------------------------- */

import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import SendIcon from '@mui/icons-material/Send';

import { ReactComponent as EditIcon } from 'img/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'img/delete-icon.svg';
import { ReactComponent as CloseIcon } from 'img/close-icon.svg';
import { ReactComponent as HomeIcon } from 'img/home-icon.svg';
import { ReactComponent as NextIcon } from 'img/next-icon.svg';
import { ReactComponent as PreviousIcon } from 'img/previous-icon.svg';

/* --------------------------------------------------------------- */

function LcButton(props) {

  return (
    
    <Button
      variant="contained"
      disableElevation
      sx={{
        borderRadius: '30px',
        padding: '14px',
        paddingLeft: '30px',
        paddingRight: '30px',
        backgroundColor: 'rgb(0, 176,185)',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'rgb(156, 219, 217)',
        }
      }}
      {...props}
    >
      {props.title}
    </Button>
  );
};

function LcSecondaryButton(props) {

  return (
    <Button
      variant="contained"
      onClick={props.onClick}
      disableElevation
      disabled={props.disabled}
      className={props?.className}
      sx={{
        borderRadius: '30px',
        padding: '14px',
        paddingLeft: '30px',
        paddingRight: '30px',
        backgroundColor: 'rgb(95, 95, 95)',
        textTransform: 'none',
        color: props.textColor || 'white',
        '&:hover': {
          backgroundColor: 'rgb(180, 180, 180)',
        }
      }}
    >
      {props.title}
    </Button>
  );
};

/* Text button, to be used on dark backgrounds. */
function LcTextButton(props) {

  let classes = ["lc-text-button"];
  if (props.className) {
    classes.push(props.className);
  }
  
  return (
    <div {...props} className={classes.join(" ")} >{props.title}</div>
  );
}

function LcIconButton(props) {

  return (
    
    <Button
      variant="contained"
      endIcon={props.endIcon}
      color={props.color}
      className={props.className}
      disableElevation
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{
        borderRadius: '30px',
        padding: '8px',
        paddingLeft: '30px',
        paddingRight: '12px',
        backgroundColor: 'rgb(0, 176, 185)',
        textTransform: 'none',
        textAlign: 'left',
        '&:hover': {
          backgroundColor: 'rgb(156, 219, 217)',
        }
      }}
    >
      {props.title}
    </Button>
  );
};
  
/* --------------------------------------------------------------- */

function LcEditButton(props) {
  
  return (
    
    <Button
      variant="outlined"
      startIcon={<EditIcon />}
      sx={{
        color: 'white',
        textTransform: 'none',
        boxSizing: 'border-box',
        padding: '5px 25px',
        height: '40px',
        borderWidth: 2,
        borderRadius: 30,
        borderColor: 'rgb(156, 219, 217)',
        '&:hover': {
          borderWidth: 2,
          backgroundColor: 'rgba(156, 219, 217, 0.3)',
          borderColor: 'rgb(156, 219, 217)',
        }
      }}
      {...props}
    >
    Edit
    </Button>
  );
};

/* --------------------------------------------------------------- */

function LcDeleteButton(props) {

  return (
    <Button
      variant="outlined"
      startIcon={<DeleteIcon />}
      sx={{
        color: 'white',
        textTransform: 'none',
        boxSizing: 'border-box',
        padding: '5px 25px',
        height: '40px',
        borderWidth: 2,
        borderRadius: 30,
        borderColor: 'rgb(240, 75, 65)',
        '&:hover': {
          borderWidth: 2,
          backgroundColor: 'rgba(240, 75, 65, 0.3)',
          borderColor: 'rgb(240, 75, 65)',
        }
      }}
      {...props}
    >
    Delete
    </Button>
  );
};

/* --------------------------------------------------------------- */

function LcCloseIconButton(props) {

  return (
    <Button
      variant="outlined"
      startIcon={<CloseIcon />}
      sx={{
        color: 'white',
        textTransform: 'none',
        padding: '15px 15px',
        minWidth: 'auto',
        borderWidth: 2,
        borderColor: 'rgb(156, 219, 217)',
        borderRadius: 14,
        '&:hover': {
          borderWidth: 2,
          borderColor: 'rgb(156, 219, 217)',
        },
        '& span ': {
          margin: 0,
        },
      }}
      {...props}
    >

    </Button>
  );
};

/* --------------------------------------------------------------- */

/* Button used on dark background; e.g. meeting form. */
function LcDarkButton(props) {

  return (
    
    <Button
      variant="contained"
      disableElevation
      sx={{
        borderRadius: '30px',
        boxSizing: 'border-box',
        height: '40px',
        paddingLeft: '30px',
        paddingRight: '30px',
        backgroundColor: 'rgb(0, 176,185)',
        textTransform: 'none',
        color: 'rgb(8, 31, 44)',
        fontSize: '14px',
        fontWieght: 'bold',
        '&:hover': {
          backgroundColor: 'rgb(156, 219, 217)',
        }
      }}
      {...props}
    >
      {props.title}
    </Button>
  );
};


/* Text button, to be used on dark backgrounds. */
function LcDarkTextButton(props) {

  return (
    <div className="lc-dark-text-button" {...props}>{props.title}</div>
  );
}

/* --------------------------------------------------------------- */

function LcHomeButton(props) {

  return (
    <Link to="/">
      <Button
        variant="contained"
        className="lc-home-button"
        startIcon={<HomeIcon />}
        onClick={props.onClick}
        disableElevation
        sx={{
          boxSizing: 'border-box',
          borderRadius: '30px',
          height: '60px',
          paddingLeft: '30px',
          paddingRight: '30px',
          backgroundColor: 'rgb(239, 239, 239)',
          color: 'black',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'rgb(235, 235, 235)',
          }
        }}
      >
        {props.title}
      </Button>
    </Link>
  );
};

/* --------------------------------------------------------------- */

function LcNextPrevButton(props) {

  return (
    <Button
      variant="contained"
      disableElevation
      {...props}
      sx={{
        boxSizing: 'border-box',
        borderRadius: '30px',
        height: '40px',
        paddingLeft: '30px',
        paddingRight: '30px',
        backgroundColor: 'white',
        color: 'black',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'rgb(235, 235, 235)',
        }
      }}
    >
      {props.title}
    </Button>
  );  
};

function LcNextButton(props) {
  return <LcNextPrevButton {...props} endIcon={<NextIcon />} />
};

function LcPreviousButton(props) {
  return <LcNextPrevButton {...props} startIcon={<PreviousIcon />} />
};

/* --------------------------------------------------------------- */

export {
  LcButton,
  LcEditButton,
  LcDeleteButton,
  LcCloseIconButton,
  LcTextButton,      /* Test + underline, you need to handle the onClick */
  LcDarkButton,      /* Used on a dark background. */
  LcDarkTextButton,  /* Used on a dark background. */
  LcIconButton,      /* Primary, blueish button with icon; used on the register page. */
  LcSecondaryButton, /* Grayish, used on the register page; e.g. `Register only` */
  LcHomeButton,
  LcNextButton,      /* Button used in the meeting room screen */
  LcPreviousButton,  /* Button used in the meeting room screen */
}

/* --------------------------------------------------------------- */
