/* --------------------------------------------------------------- */

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

/* --------------------------------------------------------------- */

function LcProgressBar(props) {
  return (
    <Box sx={{width: '100%'}} className={props?.className}>
      <LinearProgress />
    </Box>
  )
};

/* --------------------------------------------------------------- */

export {
  LcProgressBar,
};
