/* --------------------------------------------------------------- */

import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

/* ------------------------------------------------------- */

import SdHomeScreen from 'screens/SdHomeScreen'
import SdRegisterVisitorScreen from 'screens/SdRegisterVisitorScreen'
import SdRepresentativeMatchScreen from 'screens/SdRepresentativeMatchScreen'
import SdRepresentativeScheduleScreen from 'screens/SdRepresentativeScheduleScreen'
import SdRoomScheduleScreen from 'screens/SdRoomScheduleScreen'
import "./css/servicedesk.css"
import "lib/css/lib.css"
import { LcContextProvider } from 'lib/core/LcContext';

/* ------------------------------------------------------- */

function App() {

  return (
    <LcContextProvider>
        <Router>
          <Switch>
            <Route exact path="/">
              <SdHomeScreen />
            </Route>
            <Route path="/register-visitor">
              <SdRegisterVisitorScreen flow="manual" />
            </Route>
            <Route path="/badge-scan">
              <SdRegisterVisitorScreen flow="scan" />
            </Route>
            <Route path="/representative">
              <SdRepresentativeMatchScreen />
            </Route>
            <Route path="/schedule">
              <SdRepresentativeScheduleScreen />
            </Route>
            <Route path="/rooms">
              <SdRoomScheduleScreen />
            </Route>
          </Switch>
        </Router>
  
    </LcContextProvider>
  );
}

export default App;

/* ------------------------------------------------------- */
