/* --------------------------------------------------------------- */

import moment from 'moment';
import { useEffect, useState } from 'react';
import LcPageHeader from 'lib/ui/LcPageHeader';
import LcStack from 'lib/ui/LcStack';
import LcCheckbox from 'lib/ui/LcCheckbox';
import { LcClock } from 'lib/ui/LcClock';
import { LcLargeTitle, LcColumnTitle } from 'lib/ui/LcTitles';
import { LcHomeButton } from 'lib/ui/LcButton';
import { LcRepresentativeInfo } from 'lib/ui/LcRepresentative';
import { LcPageLogo } from 'lib/ui/LcLogo';
import { LcProgressBar } from 'lib/ui/LcProgress'
import { ReactComponent as AvailableIcon } from 'img/available-icon.svg';
import { ReactComponent as UnavailableIcon } from 'img/unavailable-icon.svg';
import { useLcIntervalBasedFetch, useLcFetchRepresentativesSchedule  } from 'lib/core/LcApi';
import { SdSidePanelPage, SdSidePanelBar, SdSidePanelContent } from 'ui/SdSidePanel';
import LcRepresentativeScheduleLogo from 'img/representative-schedule-oq-logo.png'

/* --------------------------------------------------------------- */

function SdAvailableIcon(props) {

  if (true == props.available) {
    return <AvailableIcon />
  }

  return <UnavailableIcon />
};

/* --------------------------------------------------------------- */

function SdScheduleLine(props) {

  return (
    <div className="lc-schedule-line">
      <div className="lc-schedule-fake-bg-vertical"></div>
      <div className="lc-schedule-rep">
        <LcRepresentativeInfo {...props} />
      </div>
        {
          props.scheduleDates.map((info, dx) => {
            return (
              <div key={dx} className="lc-schedule-ampm">
                <div className="lc-schedule-avail"><SdAvailableIcon available={info.am} /></div>
                <div className="lc-schedule-avail"><SdAvailableIcon available={info.pm} /></div>
              </div>
            )
          })
        }
    </div>
  );
};

/* --------------------------------------------------------------- */

/* All state must be kept with the user. */
function LcCheckboxList(props) {

  return (
    <div className="lc-checkbox-list">
      {
        props.toggleAllTitle && 
        <LcCheckbox
          key="all"
          onChange={props.onToggleAllChange}
          title={props.toggleAllTitle}
          checked={props.isAllToggleChecked}
          className="lc-toggle-all-checkbox"
        />
      }
      {
        props.items.map((el, dx) => {
          return (<LcCheckbox key={dx} {...el} onChange={props.onCheckboxChange}/>)
        })
      }
    </div>
  );
};

/* --------------------------------------------------------------- */

function SdRepresentativeScheduleScreen() {

  const [data, error, fetchRepresentativesSchedule] = useLcFetchRepresentativesSchedule();
  const [department_filter, setDepartmentFilter] = useState({});
  const [available_filter, setAvailableFilter] = useState({});
  const [all_departments, setFilterOnAllDepartments] = useState(false);
  const [all_availables, setFilterOnAllAvailables] = useState(false);

  let filt_available = { id: 1, title: "Available" };
  let filt_unavailable = { id: 2, title: "Unavailable" };
  let availables = [ filt_available, filt_unavailable ];

  /* Refresh the fetch every ####-millis */
  useLcIntervalBasedFetch(() => {
    fetchRepresentativesSchedule();
  }, 30000)

  useEffect(() => {

    if (!data || !data.departments) {
      return;
    }

    /* 
      The first time we fetch the data we create our filters and set 
      their initial state. Because we're refreshing the state of the 
      users every ###-millis, this effect will be executed, becuase 
      we've given a dependency with `data`, to this `useEffect()`. 
      Here we check if we extract and set the default filter values
      and if so, we return directly. 
    */
    let has_filters = Object.keys(department_filter).length;
    if (0 != has_filters) {
      return;
    }
    
    /* Set all department filters to true. */
    let init_filter = {};
    for (var i = 0; i < data.departments.length; ++i) {
      let dep = data.departments[i];
      init_filter[dep.id] = true;
    }
    
    setDepartmentFilter(init_filter);
    setFilterOnAllDepartments(true);

    /* Set all available filters to true. */
    init_filter = {};
    for (var i = 0; i < availables.length; ++i) {
      let av = availables[i];
      init_filter[av.id] = true;
    }

    setAvailableFilter(init_filter);
    setFilterOnAllAvailables(true);

  }, [data]);
  
  /* Construct the filtered department list */
  let departments = [];
  if (data) {
    let are_all_checked = all_departments;
    for (var i = 0; i < data.departments.length; ++i) {
      let dep = data.departments[i];
      dep.checked = (!!department_filter[dep.id]); 
      dep.index = i;
      departments.push(dep);
    }
  }

  /* Construct the available filters. */
  for (var i = 0; i < availables.length; ++i) {
    let av = availables[i];
    av.index = i;
    av.checked = !!available_filter[av.id];
  }

  /* --------------------------------------------------------------- */
  /* Here, we fix the data we get from the server.                   */
  /* --------------------------------------------------------------- */
  let users = [];
  
  if (data && data.users) {

    /*
      Check if the user wants to filter on department, because it
      may happen that a user doesn't have a department ID at
      all. When we're filtering on departments and the user has
      no department we have to skip the user.
    */
    let is_dep_filter_on = false;
    if (department_filter) {
      for (let dep_id in department_filter) {
        if (true == department_filter[dep_id]) {
          is_dep_filter_on = true;
          break;
        }
      }
    }

    for (let i = 0; i < data.users.length; ++i) {

      let u = data.users[i];
      
      /* Filter on availability */
      if (false == all_availables) {
        
        if (true == filt_available.checked && u.available == false) {
          continue;
        }
        
        if (true == filt_unavailable.checked && u.available == true) {
          continue;
        }
      }

      /* Filter on department: `user.department` can be null. */
      if (u.department
          && u.department
          && true == is_dep_filter_on
          && false == !!department_filter[u.department])
      {
        continue;
      }

      /* When we're filtering on department exclude users w/o one. */
      if (null == u.department
          && true == is_dep_filter_on
          && false == all_departments)
      {
        continue;
      }

      let topics = [];
      if (data.topics && u.topics) {
        topics = u.topics.map((topicId, dx) => {
          return data.topics.find((top) => top.id == topicId);
        });
      }

      let department = null;
      if (data.departments && u.department) {
        department = data.departments.find((dep) => dep.id == u.department);
      }
      
      let info = {
        ...u,
        available: u.available,
        department: department,
        topics: topics,
      };

      users.push(info);
    }
  }

  if (users) {
    //users.sort((a, b) => ((+b.available) - (+a.available)) || a.lastName.localeCompare(b.lastName));
    users.sort((a, b) => a.lastName.localeCompare(b.lastName));
  }

  /* Extract and fix the filter dates. */  
  let dates = [];
  if (data && data.users && data.users.length > 0) {
    data.users[0].scheduleDates.forEach((date) => {
      dates.push({date: moment(date.date).format("MMMM D")});
    });
  }

  /* --------------------------------------------------------------- */
  /* DEPARTMENT AND AVAILABLE LOGIC                                  */
  /* --------------------------------------------------------------- */

  const areAllDepartmentsChecked = () => {
    for (var key in departments) {
      let dep = departments[key];
      if (!department_filter[dep.id]) {
        return false;
      }
    }
    return true;
  }

  const areAllAvailablesChecked = () => {
    for (var key in availables) {
      let av = availables[key];
      if (!available_filter[av.id]) {
        return false;
      }
    }
    return true;
  };

  const onToggleDepartmentClicked = (item, ev) => {
    department_filter[item.id] = !item.checked;
    setDepartmentFilter({...department_filter});
    setFilterOnAllDepartments(areAllDepartmentsChecked());
  };
  
  const onToggleAllDepartmentsClicked = () => {

    for (var i = 0; i < departments.length; ++i) {
      let dep = departments[i];
      department_filter[dep.id] = !all_departments;
    }

    setFilterOnAllDepartments(!all_departments);
  };

  const onToggleAvailableClicked = (item, ev) => {
    available_filter[item.id] = !item.checked;
    setAvailableFilter({...available_filter});
    setFilterOnAllAvailables(areAllAvailablesChecked());
  };
  
  const onToggleAllAvailablesClicked = () => {

    for (var i = 0; i < availables.length; ++i) {
      let av = availables[i];
      available_filter[av.id] = !all_availables;
    }
    
    setFilterOnAllAvailables(!all_availables);
  };

  /* --------------------------------------------------------------- */
  /* USER LOGIC                                                      */
  /* --------------------------------------------------------------- */

  if (error) {
    /* @todo we probably want something nicer here */
    return (<div>{error}</div>);
  }

  const getContentLeft = () => {

    if (!data) {
      return null;
    }

    return (
      <>
        <strong>DEPARTMENT</strong>
        <LcCheckboxList
          items={departments}
          toggleAllTitle="Show all"
          onCheckboxChange={onToggleDepartmentClicked}
          onToggleAllChange={onToggleAllDepartmentsClicked}
          isAllToggleChecked={all_departments || areAllDepartmentsChecked()}
        />

        <strong>AVAILABILITY</strong>
        <LcCheckboxList
          items={availables}
          onCheckboxChange={onToggleAvailableClicked}
          onToggleAllChange={onToggleAllAvailablesClicked}
          isAllToggleChecked={all_availables}
        />
      </>
    );
  };

  const getContentRight = () => {

    if (!data) {
      return (<LcProgressBar />);
    }
      
    return (
      <>
        <div className="lc-schedule-fake-bg-horizontal"></div>
        <div className="lc-schedule-header">
          <div className="lc-schedule-fake-bg-vertical"></div>
          <div className="lc-schedule-header-title">Representative</div>
          {
            dates.map((info, dx) => {
              return (
                <div key={dx} className="lc-schedule-header-info">
                  <div className="lc-schedule-header-date">
                    {info.date}
                  </div>
                  <div className="lc-schedule-header-ampm">
                    <div>AM</div>
                    <div>PM</div>
                  </div>
                </div>
              )
            })
          }
        </div>
        
        <div className="lc-schedule-lines">
          {
            users.map((user, dx) => {
              return <SdScheduleLine key={dx} {...user} index={dx}/>
            })
          }
        </div>
      </>
    );
  };

  return (
    <SdSidePanelPage className="lc-schedule-page">
      <SdSidePanelBar
        showHomeButton={true}
        title={`Representative\nSchedule`}
      >
        { getContentLeft() }
      </SdSidePanelBar>
      <SdSidePanelContent>
        { getContentRight() }
      </SdSidePanelContent>
    </SdSidePanelPage>
  );
};

/* --------------------------------------------------------------- */

export default SdRepresentativeScheduleScreen;

/* --------------------------------------------------------------- */

