/* --------------------------------------------------------------- */

import { useState, useEffect, /*useContext, createContext */} from 'react';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';
import { useLcContext } from 'lib/core/LcContext'
import { jss } from 'lib/core/LcUtils';
import LcConfig from 'core/LcConfig';

/* --------------------------------------------------------------- */

//const LcApiContext = createContext();

/* --------------------------------------------------------------- */

class LcApiResult {

  constructor(axiosResponse, axiosError) {
    this.axios_resp = axiosResponse;
    this.axios_err = axiosError;
  }

  /* Checks if something went wrong (network error, api error, etc.). */
  isValid() {

    /* We're invalid when the error result has been set. */
    if (null != this.axios_err) {
      return false;
    }

    /* There was no error set, still let's check the other fields. */
    if (!this.axios_resp) {
      console.log("Checking if axios result is valid but it hasn't been set.");
      return false;
    }

    if (!this.axios_resp.status) {
      console.log("The `axios` result has not `status` member which is required.");
      return false;
    }
    
    if (200 != this.axios_resp.status) {
      console.log("Axios result is: " +this.axios_resp.status);
      return false;
    }

    return true;
  }

  /* Returns the data or NULL when something went wrong. */
  getData() {

    if (false == this.isValid()) {
      console.log("Cannot get data as the result is invalid.");
      return null;
    }
          
    return this.axios_resp.data;
  }

  /* ------------------------------------------------------- */
  
  hasApiError() {
    
    /* Do we have an error at all...? */
    if (null == this.axios_err) {
      return false;
    }

    /* 
       We assume that we have an api error when the REST endpoint
       returned a non 200 status code. E.g. status codes in the
       400 range are often considered to be client based error.
    */
    if (this.axios_err.response 
        && this.axios_err.response.status != 200)
    {
      return true;
    }

    return false;
  }

  hasNetworkError() {

    if (null != this.axios_err
        && true == !!this.axios_err.isAxiosError
        && !this.axios_err.response)
    {
      return true;
    }
    
    return false;
  }

  /*
    What kind of error did we run into?

      api:       wrong parameters, invalid user, etc. just a wrong api call
      network:   wrong url, unknown domain, no internet connection
      none:      no error at all. 
      unknown:   unknown error.

    REFERENCE:

     [0]:   https://github.com/axios/axios/issues/383#issuecomment-539499963

   */
  getErrorType() {

    if (true == this.isValid()) {
      return "none";
    }
    else if (true == this.hasApiError()) {
      return "api";
    }
    else if (true == this.hasNetworkError()) {
      return "network";
    }
    else {
      return "unknown";
    }
  }

  toString() {

    if (true == this.isValid()) {
      return JSON.stringify(this.getData(), null, 2);
    }
    
    if (this.hasApiError()) {
      return JSON.stringify(this.axios_err.response, null, 2);
    }

    if (this.axios_err) {
      return JSON.stringify(this.axios_err, null, 2);
    }

    return "unknown";
  }

  getStatusCodeString() {

    /* Handle error case */
    let status = 0;
    if (false == this.isValid()) {
      status = this.axios_err.response.status;
    }

    switch (status) {
      case 400: return "Bad Request";
      case 401: return "Unauthorized";
      case 403: return "Forbidden";
      case 404: return "Not found";
      case 405: return "Method Not Allowed";
    };

    return "Unhandled status code " +status;
  }

  generateErrorMessage(msg) {
    return msg +" (" +this.getErrorType() +")";
  }

  /* Prints debug info. */
  print() {

    if (true == this.isValid()) {
      return;
    }

    /* Get info about type of error. */
    let msg = "";
    msg += "LcApiResult: has error.\n";
    msg += "  - Type: " +this.getErrorType() +"\n";
    msg += "  - Status Message: " +this.getStatusCodeString() +"\n";
    
    if (this.axios_err.message) {
      msg += "  - Message: "+this.axios_err.message +"\n";
    }
    
    if (this.axios_err.response) {
      msg += "  - Status: " +this.axios_err.response.status +"\n";
    }

    /* Check if there is additional error info. */
    let resp = this.axios_err.response.data;
    if (resp.title) {
      msg += "  - API Message: " +resp.title +"\n";
    }
    if (resp.errors) {
      for (var key in resp.errors) {
        msg += "  - Error [" +key +"]: " +resp.errors[key] +"\n";
      }
    }

    console.error(msg);
  }
  
  /* ------------------------------------------------------- */
    
};

/* --------------------------------------------------------------- */

const LcApi = {

  /*
    voor service desk apis: authorization header meesturen met value 'ApiKey d547e4c72951409dac77acf07114e77c'
    meeting room api: authorization header meesturen met value 'ApiKey ae143b8d42664ceab1d72efcdb6ed893'
  */
  // server_url: 'https:oq-leadcapture-api.staging.alligence.com',
  // base_url: 'api/v1',
  // api_key: 'd547e4c72951409dac77acf07114e77c',

  server_url: LcConfig.server_url,
  base_url: LcConfig.base_url,
  api_key: LcConfig.api_key,

  getBaseUrl: function() {
    return this.server_url +"/" +this.base_url;
  },

  getServerUrl: function() {
    return this.server_url;
  },
  
  getAuthHeaders: function() {
    return  {
      headers: {
        'Authorization': `ApiKey ${this.api_key}`
      }
    }
  },

  /*
    This function fetches the data that is used on the
    Representative Schedule screen. The screen will do all the
    filtering and restructuring of data;.

    @param
    - eventId (integer):
    - language (string)

   */
  getServiceDeskUserSchedule: async function(options) {

    const headers = this.getAuthHeaders();

    return await axios
      .get(this.getBaseUrl() +"/servicedesk/user/schedule", { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  /*
    This function will retrieve the data that is used to 
    generate the meeting room schedule overview. 

    @param 
    - eventId (integer):
    - language (string):

   */
  getMeetingOverview: async function(options) {

    const headers = this.getAuthHeaders();
    
    return await axios
      .get(this.getBaseUrl() +"/servicedesk/meeting/overview", { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  /* 
    This function will retrieve the information that is used
    on the Meeting Detail Form. The user can either create or
    alter the meeting. 
   */
  getMeetingDetails: async function(options) {

    const headers = this.getAuthHeaders();
    
    return await axios
      .get(this.getBaseUrl() +"/servicedesk/meeting/detail", { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  /* 
    This function will retrieve the information that is used
    to build the list with representatives that are available
    TODAY and on which location they are available.  This is
    decoupled info from the representative schedule itself; this
    data is used to find/notify someone who is currently on the
    booth itself.

    @param 
    - eventId (integer):
    - language (string):

   */
  getRegistrationDataMatch: async function(options) {
    
    const headers = this.getAuthHeaders();
    
    return await axios
      .get(this.getBaseUrl() +"/servicedesk/registration/data-match", { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  /*
    This function will return the data which is required for the
    registration form. This uses the
    `/api/v1/servicedesk/registration/data` endpoint.

    @param 
    - eventId (integer):
    - language (string):

   */
  getRegistrationFormData: async function(options) {
    
    const headers = this.getAuthHeaders();
    
    return await axios
      .get(this.getBaseUrl() +"/servicedesk/registration/data", { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  /*
    This function fetches the data which is used to render the
    Meeting Room application that runs on the iPad pro.
   */
  getMeetingRoomOverviewData: async function(options) {
    
    const headers = this.getAuthHeaders();
    
    return await axios
      .get(this.getBaseUrl() +"/meetingroom/meeting/overview", { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  /*
    This function is used to retrieve the information that is
    used by the meeting room app hidden "admin" which allows the
    user to select the default meeting room.
   */
  getMeetingRoomAppData: async function(options) {

    const headers = this.getAuthHeaders();
    
    return await axios
      .get(this.getBaseUrl() +"/meetingroom/app/data", { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  saveVisitor: async function(visitor, options) {
    
    const headers = this.getAuthHeaders();
    
    return await axios
      .post(this.getBaseUrl() +"/servicedesk/registration/submit", visitor, { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  createMeeting: async function(meeting, options) {
    
    const headers = this.getAuthHeaders();
    
    return await axios
      .post(this.getBaseUrl() +"/servicedesk/meeting/create", meeting, { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  updateMeeting: async function(meeting, options) {
    
    const headers = this.getAuthHeaders();
    
    return await axios
      .post(this.getBaseUrl() +"/servicedesk/meeting/update", meeting, { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  /* 
    Delete a meeting:

    @param meeting (object);
    - event (integer)
    - meeting (integer)

   */
  deleteMeeting: async function(meeting, options) {
    const headers = this.getAuthHeaders();
    
    return await axios
      .post(this.getBaseUrl() +"/servicedesk/meeting/delete", meeting, { ...options, ...headers})
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));
  },

  /*

    GENERAL INFO:
    
      Get Scan2Lead info for the given and detected QR, Aztec,
      etc. `code` value.

    REFERENCES:
    
      [0]: https://gist.github.com/roxlu/53b7b38e4fc4fd0f00ba175c7c948c93 "Example of success result."
      
   */
  getScan2LeadInfo: async function(eventId, language, code) {
 
    if (!eventId) {
      console.log("[api] Cannot get scan2lead info as the given `eventId` is invalid.");
      return false;
    }
 
    if (!code) {
      console.log("[api] Cannot get scan2lead info as the given `code` is invalid. Pass the recognised QR/Aztec/etc. code");
      return false
    }

    let values = {
      eventId: eventId,
      language: language,
      code: code,
    };

    const headers = this.getAuthHeaders();

    /* Use dummy-server.js (see scripts dir) . */
    if (false) {
      return await axios
        .get("http://localhost:7733/scan2lead", { params: values, ...headers })
        .then(result => new LcApiResult(result, null))
        .catch(err => new LcApiResult(null, err));
    }

    return await axios
      .get(this.getBaseUrl() +"/servicedesk/app/scan2lead", { params: values, ...headers })
      .then(result => new LcApiResult(result, null))
      .catch(err => new LcApiResult(null, err));    
  },
};

/* --------------------------------------------------------------- */

/*

  This hook can be used to fetch the data that is used to build
  up the Representative Schedule Screen (the screen with the
  dates and am/pm availalility).
  
*/
const useLcFetchRepresentativesSchedule = () => {

  const ctx = useLcContext();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchRepresentativesSchedule = async () => {

    const opts = {
      params: {
        language: ctx.state.language,
        eventId: ctx.state.event_id,
      }
    };

    const result = await LcApi.getServiceDeskUserSchedule(opts);
    if (false == result.isValid()) {
      result.print();
      setError(result.generateErrorMessage("Failed to fetch representatives"));
      return;
    }

    setData(result.getData());
  }
  
  return [data, error, fetchRepresentativesSchedule];
};

/* --------------------------------------------------------------- */

/*

  This hook is used to fetch the data for the Meeting Room
  Schedule Screen. This screen shows the timeslots for each of
  the meeting room and their meetings. This can be used to
  schedule a meeting.
  
*/

const useLcFetchMeetingOverviewData = () => {

  const ctx = useLcContext();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchMeetingOverviewData = async() => {

    const opts = {
      params: {
        language: ctx.state.language,
        eventId: ctx.state.event_id,
      }
    };

    const result = await LcApi.getMeetingOverview(opts);
    if (false == result.isValid()) {
      result.print();
      setError(result.generateErrorMessage("Failed to fetch representatives"));
      return;
    }

    setData(result.getData());
  };

  return [data, error, fetchMeetingOverviewData];
};

/* --------------------------------------------------------------- */

/*
  This hook can be used to fetch the data that is used on the 
  Representative Match Screen. It shows a list with representatives
  and the available rooms.

  ```js
   {
     "locations": [
       {
         "id": 0,
         "location": "string"
       }
     ],
     "departments": [
       {
         "id": 0,
         "title": "string"
       }
     ],
     "topics": [
       {
         "id": 0,
         "title": "string"
       }
     ],
     "users": [
       {
         "id": 0,
         "lastName": "string",
         "firstName": "string",
         "available": true,
         "imageLink": "string",
         "department": 0,
         "topics": [
           0
         ]
       }
     ]
   }
  ```

*/
const useLcFetchRegistrationDataMatch = () => {

  const ctx = useLcContext();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  
  useEffect(() => {

    const fetch = async() => {

      const opts = {
        params: {
          language: ctx.state.language,
          eventId: ctx.state.event_id,
        }
      };

      const result = await LcApi.getRegistrationDataMatch(opts);
      if (false == result.isValid()) {
        result.print();
        setError(result.generateErrorMessage("Failed to fetch the data for the registration match."));
        return;
      }

      setData(result.getData());
    };

    fetch();
    
  }, []);

  return [data, error];
};

/* --------------------------------------------------------------- */

/*
  This function fetches the data that is used on the registration
  form; it does not manipulate the recieved data; see
  `LcUtils.js` for some functions that restructure the data
  before we can use it on the form.
*/
const useLcFetchRegistrationFormData = () => {
  
  const ctx = useLcContext();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  
  useEffect(() => {

    const fetch = async() => {

      const opts = {
        params: {
          language: ctx.state.language,
          eventId: ctx.state.event_id,
        }
      };

      const result = await LcApi.getRegistrationFormData(opts);
      if (false == result.isValid()) {
        result.print();
        setError(result.generateErrorMessage("Failed to fetch the data for the registration form."));
        return;
      }

      setData(result.getData());
    };

    fetch();
    
  }, []);

  return [data, error];
};

/* --------------------------------------------------------------- */

/*
  This function retrieves the information that can be used on the
  meeting detail screen. The `data` contains everything which is
  needed to build the form; some values need to be generated like
  the time ranges.

  You have to call `fetchMeetingDetails()` in an `useEffect()`. 

  The returned data looks like:

  ```js
    {
     "meeting": null,
     "eventDate": {
       "id": 5,
       "date": "20211030",
       "meetingStartTimeSlot": "0900",
       "meetingEndTimeSlot": "1800"
     },
     "meetingRooms": [
       {
         "id": 1,
         "title": "Meeting room 1",
         "timeSlotsInUse": []
       },
       {
         "id": 2,
         "title": "Meeting room 2",
         "timeSlotsInUse": []
       },
       {
         "id": 3,
         "title": "Meeting room 3",
         "timeSlotsInUse": []
       }
     ],
     "users": [
       {
         "id": 2,
         "lastName": "Huijbers",
         "firstName": "Diederick"
       },
       {
         "id": 4,
         "lastName": "Liu",
         "firstName": "Cathy "
       },
       {
         "id": 1,
         "lastName": "Mahieu",
         "firstName": "Henk"
       }
     ]
     ```
    
*/
const useLcFetchMeetingDetails = () => {
  
  const ctx = useLcContext();
  const [is_fetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  
  const fetchMeetingDetails = async (eventDateId, meetingRoomId, timeSlot) => {

    const opts = {
      params: {
        language: ctx.state.language,
        eventId: ctx.state.event_id,
        eventDateId: eventDateId,
        meetingRoomId: meetingRoomId,
        timeslot: timeSlot,
      }
    };

    /* Fetch the data. */
    setIsFetching(true);
    const result = await LcApi.getMeetingDetails(opts);
    setIsFetching(false);

    /* Did we encounter an error? Yes, generate an error string. */
    if (false == result.isValid()) {
      result.print();
      setError(result.generateErrorMessage("Failed to fetch the data for the meeting details."));
      return false;
    }

    setData(result.getData());
  };

  return [data, error, is_fetching, fetchMeetingDetails];
};
   
/* --------------------------------------------------------------- */

/*

  This function is used to save a visitor either from the
  registration form screen or from the find representative match
  screen.

*/
const useLcSaveVisitor = () => {

  const ctx = useLcContext();
  const [is_saving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);

  const saveVisitor = async (visitorData) => {
    
    setIsSaving(true);

    let data = {
      uniqueId: uuidv1(),
      type: visitorData.type,
      event: ctx.state.event_id,
      user: visitorData.user || null,
      userLocation: visitorData.user_location || null,
      firstName: visitorData.first_name,
      lastName: visitorData.last_name,
      company: visitorData.company,
      jobTitle: visitorData.job_title,
      email: visitorData.email,
      country: visitorData.country,
      receiveNewsAndUpdates: visitorData.receive_news,
      phone: visitorData.phone,
      industries: visitorData.industries,
      products: visitorData.products,
      productsLevel2: visitorData.products_level2,
      productsLevel3: visitorData.products_level3,
    };

    if ("SDS2L" == data.type) {
      data.scan2LeadCode = visitorData.badge_code;
      data.scan2LeadData = visitorData.badge_json;
    }

    let result = await LcApi.saveVisitor(data);

    setIsSaving(false);

    return result;
  };

  return [is_saving, error, saveVisitor];
};

/* --------------------------------------------------------------- */

/* 
  This hook is used on the meeting form to either create or save a 
  meeting. We return some state that can be used what we're rendering,
  e.g. disable buttons when `is_saving` is true; or show an error
  when an error happens.
*/
const useLcSaveMeeting = () => {

  const ctx = useLcContext();
  const [is_saving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);

  const saveMeeting = async (meeting) => {
    
    setIsSaving(true);

    const data = {
      language: ctx.state.language,
      event: ctx.state.event_id,
      meeting: meeting.meeting_id,
      eventDate: meeting.event_date_id,
      meetingRoom: meeting.room_id,
      timeSlots: meeting.slots,
      description: meeting.description,
      bookedByUser: meeting.booked_by_user,
      bookedByName: meeting.booked_by_name,
    };

    let result = null;
    if (false == !!meeting.meeting_id) {
      result = await LcApi.createMeeting(data);
    }
    else {
      result = await LcApi.updateMeeting(data);
    }

    setIsSaving(false);
  };

  return [is_saving, error, saveMeeting];
};

/*
  This hook can be used to delete a meeting. We expect that you
  call the returned `deleteMeeting()` function which expects a
  meeting id.
*/
const useLcDeleteMeeting = () => {

  const ctx = useLcContext();
  const [is_deleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);

  const deleteMeeting = async (meetingId) => {
    
    setIsDeleting(true);

    const data = {
      event: ctx.state.event_id,
      meeting: meetingId,
    };

    let result = await LcApi.deleteMeeting(data);
    result.print();

    setIsDeleting(false);
  };

  return [is_deleting, error, deleteMeeting];
};

/* --------------------------------------------------------------- */

const useLcFetchMeetingRoomOverviewData = () => {

  const ctx = useLcContext();
  const [is_fetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  
  const fetchMeetingRoomOverviewData = async () => {

    const opts = {
      params: {
        language: ctx.state.language,
        eventId: ctx.state.event_id,
      }
    };

    /* Fetch the data. */
    setIsFetching(true);
    const result = await LcApi.getMeetingRoomOverviewData(opts);
    setIsFetching(false);

    /* Did we encounter an error? Yes, generate an error string. */
    if (false == result.isValid()) {
      result.print();
      setError(result.generateErrorMessage("Failed to fetch the data for the meeting room."));
      return false;
    }

    setData(result.getData());
  };

  return [data, error, is_fetching, fetchMeetingRoomOverviewData];
};

/* --------------------------------------------------------------- */

function useLcFetchMeetingRoomAppData() {

  const ctx = useLcContext();
  const [is_fetching, setIsFetching] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetchMeetingRoomAppData = async () => {

    if (!ctx || !ctx.state || !ctx.state.language) {
      console.error("State is invalid; no language set.");
      return false;
    }
    
    const opts = {
      params: {
        language: ctx.state.language,
      }
    };
    
    /* Fetch the data. */
    setIsFetching(true);
    const result = await LcApi.getMeetingRoomAppData(opts);
    setIsFetching(false);

    /* Did we encounter an error? Yes, generate an error string. */
    if (false == result.isValid()) {
      result.print();
      setError(result.generateErrorMessage("Failed to fetch the application data for the meeting."));
      return false;
    }

    setData(result.getData());
  };

  return [data, error, is_fetching, fetchMeetingRoomAppData];
};

/* --------------------------------------------------------------- */

function useLcIntervalBasedFetch(callback, timeInMillis) {

  useEffect(() => {
    
    /* Fetch the data gain after ####-millis. */
    const handle = window.setInterval(() => {
      callback();
    }, timeInMillis);

    /* Initial fetch */
    callback();

    return () => window.clearInterval(handle);

  }, []);
};

/* --------------------------------------------------------------- */

export {
  LcApi,
  useLcIntervalBasedFetch,
  useLcFetchRepresentativesSchedule,
  useLcFetchMeetingOverviewData,
  useLcFetchRegistrationDataMatch,
  useLcFetchRegistrationFormData,
  useLcFetchMeetingDetails,
  useLcFetchMeetingRoomOverviewData,
  useLcFetchMeetingRoomAppData,
  useLcSaveVisitor,
  useLcSaveMeeting,
  useLcDeleteMeeting,
};

/* --------------------------------------------------------------- */
